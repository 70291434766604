export const Rules = [
    { label: 'Agravated / Molesto', descripcion: 'Si algún malote ha sido derrotado en esta batalla, esta unidad gana +2 <b style="font-color:#00008b ">Atk 🗡️</b>.' },
    { label: 'Assaut / Asalto', descripcion: 'La estadística de <b style="font-color:#00008b ">Atk 🗡️</b> de esta unidad es igual a su actual # <b style="font-color:#b30000 ">HP ❤️</b>.' },
    { label: 'Bleed / Sangrar', descripcion: 'Coloca el dado de efecto Sangrar en el  <b>objetivo</b> . Esta unidad recibe 1  <b style="font-color:black;text-decoration: underline;">daño directo</b>  al inicio de su turno (dura toda la batalla). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/sangrar.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>' },
    { label: 'Blind Strike / Golpe a ciegas', descripcion: 'Al comienzo del turno, esta unidad inflige # <b style="font-color:black;">Dmg 💥</b> a la unidad adyacente más fuerte' },
    { label: 'Break / Romper', descripcion: 'Cualquier dado de <b style="font-color:#00008b ">Atk 🗡️</b>usado para reducir la <b style="font-color:#b30000 ">HP ❤️</b>de esta unidad debe agotarse' },
    { label: 'Bribable / Sobornable', descripcion: 'Una vez por turno de una unidad enemiga, un Gearloc puede descartar un botín para tener inmediatamente un turno extra en lugar de esta unidad (tratar como un aliado)'},
    { label: 'Brimstone / Azufre', descripcion: 'Cada vez que una unidad enemiga se mueva a una posición adyacente a esta unidad, esta unidad gana inmediatamente un dado de <b style="font-color:#ffbf00;">Def 🛡️</b> 2 y, si es posible, se mueve 1 posición en la dirección opuesta a la posición actual de la unidad enemiga.'},
    { label: 'Callous / Insensible', descripcion: 'Esta unidad sólo puede perder <b style="font-color:#b30000 ">HP ❤️</b>por daño producido por dados de <b style="font-color:#00008b ">Atk 🗡️</b> (el  <b style="font-color:black;text-decoration: underline;">daño directo</b>  y la fatiga también se ignoran)'},
    { label: 'Careless / Descuidado', descripcion: 'Esta unidad recibe # de  <b style="font-color:black;text-decoration: underline;">daño directo</b>  (sin # cuenta como 1)'},
    { label: 'Compound / Agravar', descripcion: 'El <b style="font-color:#00008b ">Atk 🗡️</b>de esta unidad es igual al # de ronda actual (fatiga cuenta como 5)'},
    { label: 'Confuse / Confundir', descripcion: 'Coloca un dado de efector Confundir en el  <b>objetivo</b>; si este efecto ya estuviese ahi, elimínalo. Las estadisticas de <b style="font-color:#00008b ">Atk 🗡️</b>y <b style="font-color:#ffbf00;">Def 🛡️</b> de la unidad se intercambian. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/confundir.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'}, 
    { label: 'Corrosive / Corrosivo', descripcion: 'Al final del turno de esta unidad, los dados de <b style="font-color:#ffbf00;">Def 🛡️</b> restantes de las ranuras Activas de su(s)  <b>objetivo</b> (s) deben agotarse inmediatamente.'}, 
    { label: 'Cunning / Astuto', descripcion: 'Las unidades enemigas sólo  pueden atacar a esta unidad si es el más fuerte de los Malotes en el tapete de batalla.'}, 
    { label: 'Daze / Atontar', descripcion: 'Coloca un dado de efecto Atontar en el  <b>objetivo</b>  hasta el final de su próximo turno. La unidad no puede moverse en su turno. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/atontar.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'}, 
    { label: 'Decoy / Señuelo', descripcion: 'Cuando es derrotado, coloca un dado de efecto Señuelo en la posicoón con # <b style="font-color:#b30000 ">HP ❤️</b>. El grupo trata al Señuelo como unidad enemiga. Mientras el Señuelo esté en el tapete de batalla, los Gearlocs no pueden atacar a Malotes. El Señuelo no prolonga la batalla si los Malotes son derrotados. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/senuelo.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'}, 
    { label: 'Detonate / Detonar', descripcion: 'Al final de la fase de movimiento, si esta unidad está por debajo de su <b style="font-color:#b30000 ">HP ❤️</b> máxima, se derrotará a sí misma e infligirá #  <b style="font-color:black;text-decoration: underline;">daño directo</b>  a cada posición adyacente.'}, 
    { label: 'Disruption / Disrupción', descripcion: 'Avanza el dado de contador de rondas a (fatiga) al final de la ronda.'},
    { label: 'Dive / En picado', descripcion: 'Si el dado de efecto vuelo (Flight) está activo en esta unidad al inicio de su turno, coloca esta unidad adyacentemente a la unidad enemiga más débil (y la selecciona como  <b>objetivo</b> ).'},
    { label: 'Dodge / Esquivar', descripcion: 'La <b style="font-color:#b30000 ">HP ❤️</b>de esta unidad no puede reducirse con dados de <b style="font-color:#00008b ">Atk 🗡️</b>.'},
    { label: 'Engulf / Sepultar', descripcion: 'El <b style="font-color:black;">Dmg 💥</b> obtenido en la tirada por esta unidad también impacta a todas las unidades adyacentes al  <b>objetivo</b>  (includo a sí mismo), así como también activa sus habilidades de reacción, si corresponde.'},
    { label: 'Eruption / Erupción', descripcion: 'Al final del turno de esta unidad, las posiciones de sus  <b>objetivo</b> s se convierten en Lava.'},
    { label: 'Equipment / Equipo', descripcion: 'Cuando esta unidad entre en el tapete de batalla, lanza un D6. Pieza de armadura (1-2): Aumenta la estadistica de <b style="font-color:#b30000 ">HP ❤️</b> en 2. Piedra de afilar (3-4): Aumenta la estadistica de <b style="font-color:#00008b ">Atk 🗡️</b>en 1 (coloca el dado de <b style="font-color:#00008b ">Atk 🗡️</b>en el Malote para recordar la bonificación). Gafas (5-6): Aumenta su dado de Ini en 2.'},
    { label: 'Flame Soak / Inmersion de llamas', descripcion: 'Elimina # <b style="font-color:#b30000 ">HP ❤️</b> del Gearloc más cercano en una posición de Lava y coloca los <b style="font-color:#b30000 ">HP ❤️</b> bajo esta unidad (puede superar su valor de HP). Si no hay Gearlocs en la Lava, elimina los <b style="font-color:#b30000 ">HP ❤️</b>del MAlote más cercano en una posiicón de Lava, si es posible.'},
    { label: 'Flee / Huye', descripcion: 'Retira esta unidad del tapete de batalla al final de las rondas 2 y 5 y colócala en la pila de derrotados. Si esto sucede, la unidad enemiga más cercana con un botín debe descartar 1.'},
    { label: 'Flight / Vuelo', descripcion: 'Después de que esta unidad ataque, coloca el dado de efecto de Vuelo sobre ella. Si el efecto ya estaba, retíralo. Mientras esté en Vuelo, esta unidad es <b>Inalcanzable</b> (Untargetable). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/vuelo.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Frenzy / Frenesí', descripcion: 'Si al menos # dados de <b style="font-color:#00008b ">Atk 🗡️</b>de esta unidad golpean (Obtienen espada en los dados), lanza # dados de <b style="font-color:#00008b ">Atk 🗡️</b>nuevamente e inflige el <b style="font-color:black;">Dmg 💥</b> total de ambas tiradas.'},
    { label: 'Hardy / Duro', descripcion: 'En cualquier turno en el que esta unidad pierda <b style="font-color:#b30000 ">HP ❤️</b> (Incluyendo por  <b style="font-color:black;text-decoration: underline;">daño directo</b> ), el total de <b style="font-color:#b30000 ">HP ❤️</b> perdida se reduce a 1.'},
    { label: 'Inspire / Inspirar', descripcion: 'El siguiente Malote en el Ini-metro juega su turno inmediatamente a continuación de esta unidad (mueve su dado de Ini) y recibe # dados de <b style="font-color:#00008b ">Atk 🗡️</b>adicionales ese turno.'},
    { label: 'Intense / Intenso', descripcion: 'El Plan B del  <b>objetivo</b>  pierde # 🦴 (eliminar primero los 🦴 más a la izquierda)'},
    { label: 'Lashback / Contraataque', descripcion: 'En cualquier turno en el que esta unidad pierda <b style="font-color:#b30000 ">HP ❤️</b>por Daño infligido por una unidad enemiga adyacente, le infligirá # <b style="font-color:black;">Dmg 💥</b> de vuelta, siempre y cuando no haya sido derrotada.'},
    { label: 'Lava Flow / Flujo de Lava', descripcion: 'Cualquier posición de roca que ocupe esta unidad se convierte inmediatamente en Lava.'},
    { label: 'Mischief / Travieso', descripcion: 'Retira # dados (a elección del juegador) de las ranuras Activas del  <b>objetivo</b>  antes de atacar.'},
    { label: 'Molten Bath / Daño fundido', descripcion: 'Esta unidad se cura # <b style="font-color:#b30000 ">HP ❤️</b> al inicio de su turno si su posición es Lava y se cura # <b style="font-color:#b30000 ">HP ❤️</b> cada vez que se mueva a una posición de Lava.'},
    { label: 'One Mind / Una mente', descripcion: 'En el turno de esta unidad, todos los demás Malotes de tipo (Morado) tiran 1 dado de <b style="font-color:#00008b ">Atk 🗡️</b>contra sus  <b>objetivo</b> s.'},
    { label: 'Poison / Veneno', descripcion: 'Coloca o resetea el dado de efecto Veneno a #. El  <b>objetivo</b>  recibe  <b style="font-color:black;text-decoration: underline;">daño directo</b>  igual al # del dado de efecto al inicio de su turno. Después de aplicar el daño reduce el dado de efecto en 1. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/veneno.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Pollute / Contaminar', descripcion: 'Coloca un dado de efecto de Veneno # a todos los Gearlocs que no estén en una posición de Lava. Consulta Veneno # (Poison #) para obtener más detalles. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/veneno.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Pull-in / Tirar', descripcion: 'El miembro del grupo  <b>objetivo</b>  se coloca en al posición inicial krlln adyacente a esta unidad, si fuese posible.'},
    { label: 'Rage / Furia', descripcion: 'Si esta unidad no tiene su <b style="font-color:#b30000 ">HP ❤️</b> al completo, obtiene # dados de <b style="font-color:#00008b ">Atk 🗡️</b>adicionales.'},
    { label: 'Raiding / Incursión', descripcion: 'Esta unidad obtiene 1 dado de <b style="font-color:#00008b ">Atk 🗡️</b>extra por cada Malote de tipo Orco (Azul) adicional en el tapete de batalla.'},
    { label: 'Randomize / Aleatorizar', descripcion: 'Roba un dado de Tirano al azar de Splice & Dice que no esté en uso. Este Tirano lo utiliza como propio para esta batalla.'},
    { label: 'Reach / Alcance', descripcion: 'Al comienzo de turno de esta unidad, la unidad enemiga más cercana que no esté ya adyacente se coloca en una posición disponible adyacente a esta unidad, si es posible. Si hay disponible una posición de Lava adyacente, la unidad enemiga debe colocarse en ella.'},
    { label: 'Recover / Recuperar', descripcion: 'Esta unidad obtiene # <b style="font-color:#b30000 ">HP ❤️</b> (hasta su máximo) al inicio de su turno.'},
    { label: 'Return / Retornar', descripcion: 'Si este no es el último Malote derrotado en esta batalla, colócalo en la parte superior de la BQ cuando sea derrotado.'},
    { label: 'Secrete / Secretar', descripcion: 'Cuando sea atacado por un enemigo adyacente, agrega el dado de efecto Veneno # a esa unidad (incluso si es derrotada). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/veneno.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Self Repair / Autoreparación', descripcion: 'Al comienzo del turno de esta unidad, coloca sobre ella dados de <b style="font-color:#ffbf00;">Def 🛡️</b> por un valor total de # (hasta la estadística de escudo dorado)'},
    { label: 'Shock / Conmoción', descripcion: 'Agota # dados de habilidad (a elección del juegador) del área de habilidades del  <b>objetivo</b>  (excluyendo los dados de contador).'},
    { label: 'Signal / Aviso', descripcion: 'Al comienzo del turno de esta unidad, agrega un Malote de menor Pt a la parte inferior de la BQ (20Pt avisa a 5Pt, 5Pt avisa a 1Pt, 1Pt avisa a 1Pt). Después de que se haya avisado a # Malotes, esta habiliadd no se vuelve a activar.'},
    { label: 'Smokescreen / Cortina de humo', descripcion: 'Mientras ocupe una posición de Lava, esta unidad no puede ser seleccionada como  <b>objetivo</b>  (pero aún puede recibir daño de habilidades no  <b>objetivo</b>  y otros efectos).'},
    { label: 'Stench / Hedor', descripcion: 'Al ser derrotado, coloca el dado de efecto Hedor # en esta posición. Se trata como un Obstáculo no atacable. Cuando se color Hedor todas las unidades adyacentes a este dado reciben inmediatamente #  <b style="font-color:black;text-decoration: underline;">daño directo</b> . Además, los Gearlocs que se muevan adyacentes a Hedor reciben 1  <b style="font-color:black;text-decoration: underline;">daño directo</b> . Al inicio de la ronda, reduce el dado de efecto en 1. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/hedor.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Stout / Robusto', descripcion: 'El último <b style="font-color:#b30000 ">HP ❤️</b> de esta unidad sólo puede ser eliminado por Fatiga.'},
    { label: 'Stubborn / Testarudo', descripcion: 'Cuando esta unidad es derrotada, realiza inmediamente un turno antes de ser retirada del tapete de batalla.'},
    { label: 'Stun / Aturdir', descripcion: 'Coloca un dado de efecto Aturdir sobre el  <b>objetivo</b> . El  <b>objetivo</b>  pierde su siguiente turno (los efectos negativos aún se aplican). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/aturdir.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Submerge / Sumergirse', descripcion: 'Si esta unidad se moviera, en su ligar lanza un D6 y colócalo en la posición inicial krelln #.'},
    { label: 'Swap / Intercambio', descripcion: 'Al final del turno de esta unidad, cambiará de posición con la unidad enemiga más cercana y más fuerte.'},
    { label: 'Swordbreaker / Rompeespadas', descripcion: 'Al final del turno de esta unidad, sus onjetivos debem agotar 1 de dus dados de <b style="font-color:#00008b ">Atk 🗡️</b>si han perdido <b style="font-color:#b30000 ">HP ❤️</b> o bonificación de <b style="font-color:#b30000 ">HP ❤️</b> este turno.'},
    { label: 'Taunt / Provocación', descripcion: 'Coloca el dado de efecto Provocación en esta unidad hasta el inicio de su sigueinte turno. Los enemigos adyacentes deben seleccionar esta unidad com o <b>objetivo</b>  (las unidades con múltiples  <b>objetivo</b> s atacarán a esta unidad múltiples veces). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/provocacion.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Terrify / Aterrorizar', descripcion: 'Después de que esta unidad sea atacada, coloca un dado de efecto de Aterroziar sobre el atacante hasta el final de su próximo turno. Si una unidad tiene un efecto de Aterrorizar en él al comienzo de su próximo turno, no puede sleeccionar a ninguna unidad con habilidad Aterrorizar. <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/aterrorizar.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Thick Skin / Piel gruesa', descripcion: 'Ignora la primera # de <b style="font-color:black;">Dmg 💥</b> (sin # cuenta como 1) que esta unidad fuera a recibir este turno (no ignora el  <b style="font-color:black;text-decoration: underline;">daño directo</b> )'},
    { label: 'Trap / Trampa', descripcion: 'Encuentros no acuáticos solamente. Un Gearloc a elección del grupo tira un D6 al entrar en el tapete de batalla. Trampa de gas (1-2): Lanza el dado de efecto Veneno y aplícale los resultados. Trampa eléctrica (3-4): no puede usar habilidades durante la ronda 1 (usa el dado de efecto Desactivar (Disable) como recordatorio). Trampa de púas (5-6): Mueve 2 posiciones hacia abajo en el Ini-metro.'},
    { label: 'Treasure / Botín', descripcion: 'Cuando esta unidad sea derrotada, coloca un dado de Botín en su posición (si no tienes dado de botín, roba un botín)'},
    { label: 'Trickery / Engaño', descripcion: 'Cuando esta unidad entre en el tapete de batalla, añade inmediatamente otro Malote: Goblin Guru (20Pt, sólo de las pilas Activas) a la parte superior de la BQ. Cuando 2 Goblins Gurus están en el tapete de batalla, el <b style="font-color:black;">Dmg 💥</b> infligido a una unidad sólo se aplica a la otra.'},
    { label: 'Undead / No muerto', descripcion: 'La primera vez que esta unidad sea derrotada, colócala de nuevo en la parte superior de la BQ. Cuando vuelve a entrar en juego, comienza con sólo 2 <b style="font-color:#b30000 ">HP ❤️</b>, y su estadística de dados de <b style="font-color:#00008b ">Atk 🗡️</b> se duplica.'},
    { label: 'Untargetable / Inalcanzable', descripcion: 'Coloca el dado de efecto Inalcanzable sobre esta unidad. Hasta el inicio del siguiente turno de esta unidad, no puede ser seleccionada com  <b>objetivo</b>  por unidades enemigas (pero si se le puede infligir <b style="font-color:black;">Dmg 💥</b> con habilidades sin  <b>objetivo</b>  y otros efectos). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/inalcanzable.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
    { label: 'Weaken / Debilitar', descripcion: 'Coloca un dado de efecto Debilitar # sobre el  <b>objetivo</b> . La <b style="color: green">Dex ✋</b> del Gearloc se reduce en # el siguiente turno (otras unidades reducen su <b style="font-color:#00008b ">Atk 🗡️</b>). <br /><br /><p style="text-align:center;"><img src="/static/resources/dice/debilitar.png" style="object-fit: cover;width: 80px;height: 80px;"/></p>'},
  ];