import React, { useEffect, useState, useRef } from 'react'; // Importar useState
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { Rules } from './Rules'; // Correctly import the rules array
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';

const LibroBatalla = ({ isExpanded, onClose }) => {
    const [openLibroBatalla, setOpenLibroBatalla] = useState(false); // Initialize the open state to false
    const [selectedRule, setSelectedRule] = useState(null); // State to store the selected rule
    const inputRef = useRef(null); // Create a reference to the input field
    const [open, setOpen] = useState(false); // Initialize the open state to false

    const [libroBatalla, setLibroBatalla] = useState([  
    ]);

    const audioRef = useRef(null); // Create a reference to the audio element

    useEffect(() => {
        setOpen(isExpanded);
    }, [isExpanded]);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw', // Set the width to 90% of the viewport width
        maxWidth: '90vw', // Ensure the maximum width is 90% of the viewport width
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    };


    const handleRuleChange = (event, newValue) => {
        setSelectedRule(newValue);
        if (inputRef.current) {
            inputRef.current.blur(); // Remove focus from the input field
        }
    };

    const handleDelete = (index) => {
        setLibroBatalla((prevLibroBatalla) => prevLibroBatalla.filter((_, i) => i !== index));
    };

    const handleClickLibroBatalla = () => {
        setOpenLibroBatalla(!openLibroBatalla);
    };

    const removeImgTags = (html) => {
        return html.replace(/<img[^>]*>/g, '');
    };

    const handleAddToLibroBatalla = () => {
        if (selectedRule && !libroBatalla.some(item => item.label === selectedRule.label)) {
            setLibroBatalla((prevLibroBatalla) => [
                ...prevLibroBatalla,
                { label: selectedRule.label, descripcion: selectedRule.descripcion }
            ]);
            if (audioRef.current) {
                audioRef.current.play(); // Play the sound
            }
        }
    };

    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style} className="modal-rules">
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>



                <h3>Reglas</h3>
                <div className="autocomplete">
                    <Autocomplete
                    disablePortal
                    options={Rules}
                    sx={{ width: 300 }}
                    onChange={handleRuleChange} // Update selected rule
                    renderInput={(params) => <TextField 
                                                {...params} 
                                                label="Terminos" 
                                                inputRef={inputRef} // Attach the reference to the input field
                                            />}
                />
                </div>
                <div className="rules-info">
                            {selectedRule ? (
                        <div
                                dangerouslySetInnerHTML={{
                                    __html: `<h3>${selectedRule.label}</h3><div style="font-size:16px;text-align: justify;">${selectedRule.descripcion}</div>`
                                }}
                            />

                            ) : (
                                'Busca la regla que necesitas'
                            )}
                </div>
                        <div className="seccion-boton">
                            <Button
                                variant="contained"
                                style={{ backgroundColor: 'black', color: 'white' }}
                                startIcon={<AutoStoriesIcon style={{ color: 'white' }} />}
                                onClick={handleAddToLibroBatalla}
                            >
                                Añadir al Libro de batalla
                            </Button>
                        </div>
                        <div className="seccion-boton-cerrar">
                        <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                Cerrar reglas
                            </Button>
                            </div>
                    </Typography>
                </Box>
            </Modal>

            <div className="batalla">
                <List
                    sx={{ width: '100%', bgcolor: 'black', color: 'white' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItemButton onClick={handleClickLibroBatalla}>
                        <ListItemIcon>
                            {libroBatalla.length > 0 && (
                                <Badge color="secondary" badgeContent={libroBatalla.length}>
                                    <AutoStoriesIcon style={{ color: 'white' }} />
                                </Badge>
                            )}
                            {libroBatalla.length === 0 && (
                                <AutoStoriesIcon style={{ color: 'white' }} />
                            )}
                        </ListItemIcon>
                        <ListItemText primary="Libro de batalla" />
                        {openLibroBatalla ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openLibroBatalla} timeout="auto" unmountOnExit>
                        <List component="div">
                            {libroBatalla.map((item, index) => (
                                <ListItem key={index} component="div">
                                    <ListItemButton sx={{ pl: 1 }}>
                                        <ListItemIcon>
                                            <DeleteForeverIcon onClick={() => handleDelete(index)} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<span style={{ fontWeight: 'bold', color: 'rgb(255, 191, 0)' }}>{item.label}</span>}
                                            secondary={<span style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: removeImgTags(item.descripcion) }} />}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
            </div>

            {/* Audio element for playing sound */}
            <audio ref={audioRef} src="/static/resources/librobatalla.mp3" />
        </div>
    );
};

export default LibroBatalla;