import React from 'react';

const UsoLocal = (UsoLocal) => {
    // USO_LOCAL can be firebase, azure, local
    const USO_LOCAL = 'azure'; 
    var result = '';
    var isEmpty = (UsoLocal === '')


    if (USO_LOCAL == 'firebase') {
        result = 'https://firebasestorage.googleapis.com/v0/b/tmbcards-3ec94.appspot.com/o/' + UsoLocal;
    } else if (USO_LOCAL == 'azure') {
        result = 'https://tmbadventures.blob.core.windows.net/website/' + UsoLocal;
    } else {
        result = '/static/' + UsoLocal;
    }



    return result;
}

export default UsoLocal;