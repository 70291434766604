import './CombateTirano.css';
import React, { useEffect, useState, useRef } from 'react'; // Importar useState
import { useLocation } from 'react-router-dom';
import { useStateContext } from './StateContext'; // Importa el hook del contexto
import { useNavigate } from 'react-router-dom';
import Audio from './Audio';
import UsoLocal from './UsoLocal';
import Fab from '@mui/material/Fab';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import LibroBatalla from './LibroBatalla';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import TimelineIcon from '@mui/icons-material/Timeline';
import LightModeIcon from '@mui/icons-material/LightMode';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import InfoIcon from '@mui/icons-material/Info';
import Tirano from './FAQ/Tirano';


const CombateTirano = () => {


    const location = useLocation(); // Hook para obtener el estado de la navegación
    const navigate = useNavigate();
    const [open, setOpen] = useState(false); // Initialize the open state to false

    const { updatedDeckInfoJSON, verCartaTirano } = location.state || {}; // Obtén la cadena JSON del estado

    const deckInfo = updatedDeckInfoJSON ? JSON.parse(updatedDeckInfoJSON) : {}; // Parsear JSON si es una cadena
    const [gearlocs, setGearlocs] = useState(deckInfo.gearlocs);
    const [tiranoName, setTiranoName] = useState(''); // Initialize the gearlocName state

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw', // Set the width to 90% of the viewport width
        maxWidth: '90vw', // Ensure the maximum width is 90% of the viewport width
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    };

    // Verifica que deckInfo no sea undefined
    //console.log('combate Tirano deckInfo:', updatedDeckInfoJSON);

    const { numDays, limDays, bqValue, progress, limProgress, updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress, updateHeaderFieldDaysColor } = useStateContext(); // Usa el contexto para actualizar el estado

    const { id, selectedVillain, numPlayers, cardList, progresoActual, diasActual, cartaDelMazo, revisarEncuentros, objetoNoSubirDia, objetoEncuentro34Dia } = deckInfo;

    const [initialized, setInitialized] = useState(false); // Estado para controlar la inicialización
    const [selectedRule, setSelectedRule] = useState(null); // State to store the selected rule
    const inputRef = useRef(null); // Create a reference to the input field

    // Estado para controlar si se muestra la imagen frontal o la trasera
    const [showBackImage, setShowBackImage] = useState(false);
    const [imageNumber, setImageNumber] = useState(0); // Estado para controlar el número de la imagen actual

    const [showQuestionBox, setShowQuestionBox] = useState(false);
    const [questionText, setQuestionText] = useState("");
    const [button1Text, setButton1Text] = useState("");
    const [button2Text, setButton2Text] = useState("");
    const [button1Action, setButton1Action] = useState(() => {});
    const [button2Action, setButton2Action] = useState(() => {});
    const [zoomLevel, setZoomLevel] = useState(1);

    const ZoomIcon = document.getElementById('zoom-icon');

    // Función para mostrar la pregunta con texto y funciones dinámicas
    const showQuestion = (question, btn1Text, btn2Text, btn1Action, btn2Action) => {
        setQuestionText(question);
        setButton1Text(btn1Text);
        setButton2Text(btn2Text);
        setButton1Action(() => btn1Action);
        setButton2Action(() => btn2Action);
        setShowQuestionBox(true);
    };

    useEffect(() => {
        if (deckInfo && !initialized) {
            updateProgress(deckInfo.progresoActual); // Actualiza el progreso inicial
            updateLimProgress(selectedVillain.puntosNecesarios); // Actualiza el límite de progreso
            updateNumDays(diasActual); // Actualiza el número de días
            updateLimDays(selectedVillain.limiteDias); // Actualiza el límite de días
            updateBqValue(diasActual, numPlayers); // Actualiza el valor BQ

            setInitialized(true);

        }
    }, [deckInfo, updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress]);

    const handleImageClick = () => {
        // Alterna entre la imagen frontal y la trasera
        setShowBackImage(!showBackImage);
    };

    const getUrlImageTirano = (imgNumber) => {
        return UsoLocal('tiranos%2Ftirano_'+selectedVillain.clave+'_'+selectedVillain.keyname+'_'+imgNumber);
    }

    const getVillainImage = (imgNumber) => {
        // Usa la imagen trasera si showBackImage es verdadero
        return !showBackImage ? getUrlImageTirano(imgNumber)+'_frontal.jpg?alt=media': getUrlImageTirano(imgNumber)+'_trasera.jpg?alt=media';
    };

    const getVillainAudio = (imgNumber) => {
        if (!selectedVillain) return null;

        return UsoLocal('audio/tiranos%2Ftirano_'+selectedVillain.clave+'_'+selectedVillain.keyname+'_'+imgNumber+'_frontal.mp3');
    }
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (tiranoName) => {
        setTiranoName(tiranoName);
        setOpen(true);
    };

    
    // Sync: LIBRO DE BATALLA
    const [isMiniDocExpanded, setIsMiniDocExpanded] = useState(false);

    const expandMiniDoc = () => {
        setIsMiniDocExpanded(!isMiniDocExpanded);
    };

    const closeMiniDoc = () => {
        setIsMiniDocExpanded(false);
    };

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel === 1 ? 1.2 : 1;
            ZoomIcon.style.color = newZoomLevel === 1 ? '#353535' : '#C21807';
            return newZoomLevel;
        });
    };


    // Verificar si la propiedad cartaExtra es igual a 1
    const showVillainControls = selectedVillain.cartaExtra > 0;

    const volverAventura = () => {
        const deckInfoRecibida = {
            id: id,
            numPlayers: numPlayers,
            gearlocs: gearlocs,
            selectedVillain: {
                name: selectedVillain.name,
                keyname: selectedVillain.keyname,
                encuentrosTirano: selectedVillain.encuentrosTirano,
                puntosNecesarios: selectedVillain.puntosNecesarios,
                limiteDias: selectedVillain.limiteDias,
                clave: selectedVillain.clave,
                cartaExtra: selectedVillain.cartaExtra,
            },
            diasActual: diasActual - 1,
            cartaDelMazo: cartaDelMazo,
            progresoActual: progresoActual,
            revisarEncuentros: revisarEncuentros,
            objetoNoSubirDia: objetoNoSubirDia,
            objetoEncuentro34Dia: objetoEncuentro34Dia,
            cardList: cardList
        };
        const deckInfoJSON = JSON.stringify(deckInfoRecibida, null, 2);
        console.log('volverAventura: ',deckInfoJSON);
        updateHeaderFieldDaysColor('red-background'); // Cambia el color a verde
        setTimeout(() => {
            updateHeaderFieldDaysColor(''); // Restablece el color después de 0.5 segundos
        }, 500);

        navigate('/Aventura', { state: { deckInfoJSON } });

    };

    const handlePreviousCard = () => {
        setImageNumber((prevNumber) => (prevNumber > 0 ? prevNumber - 1 : prevNumber)); //Decrementa hasta 0
    };
    
    const handleNextCard = () => {
        setImageNumber((prevNumber) => (prevNumber < selectedVillain.cartaExtra ? prevNumber + 1 : prevNumber)); //Incrementa hasta 1
    };

    const finalizarAventura = () => {
        //IR A EPILOGO (VICTORIA)
        showQuestion("¿Cómo ha terminado el combate?", "He ganado", "Me han derrotado", epilogoVictoria, epilogoDerrota);
        setShowQuestionBox(true);
    };

    const epilogoVictoria = async () => {

        const registrarPartida = {
            gearlocs: gearlocs.map(g => g.name).join(', '), // Convert gearlocs to a comma-separated string
            tirano: selectedVillain.name,
            state: 'win'
        };

        try {
            // Post the deckInfo to the server
            const response = await axios.post('https://tmbadventures.es/api/game_state', registrarPartida);
            console.log('Data inserted:', response.data);
        } catch (error) {
            console.error('Error inserting data:', error);
        }


        const deckInfoActualizada = {
            id: id,
            numPlayers: numPlayers,
            gearlocs: gearlocs,
            selectedVillain: {
                name: selectedVillain.name,
                keyname: selectedVillain.keyname,
                encuentrosTirano: selectedVillain.encuentrosTirano,
                puntosNecesarios: selectedVillain.puntosNecesarios,
                limiteDias: selectedVillain.limiteDias,
                clave: selectedVillain.clave,
                cartaExtra: selectedVillain.cartaExtra,
            },
            diasActual: diasActual,
            cartaDelMazo: cartaDelMazo,
            progresoActual: progresoActual,
            cardList: cardList
        };
        const deckInfoJSON = JSON.stringify(deckInfoActualizada, null, 2);

        navigate('/Epilogo', { state: { deckInfoJSON, resultado:'victoria' } });
    };

    const epilogoDerrota = async () => {

        const registrarPartida = {
            gearlocs: gearlocs.map(g => g.name).join(', '), // Convert gearlocs to a comma-separated string
            tirano: selectedVillain.name,
            state: 'lose'
        };

        try {
            // Post the deckInfo to the server
            const response = await axios.post('https://tmbadventures.es/api/game_state', registrarPartida);
            console.log('Data inserted:', response.data);
        } catch (error) {
            console.error('Error inserting data:', error);
        }

        const deckInfoActualizada = {
            numPlayers: numPlayers,
            gearlocs: gearlocs,
            selectedVillain: {
                name: selectedVillain.name,
                keyname: selectedVillain.keyname,
                encuentrosTirano: selectedVillain.encuentrosTirano,
                puntosNecesarios: selectedVillain.puntosNecesarios,
                limiteDias: selectedVillain.limiteDias,
                clave: selectedVillain.clave,
                cartaExtra: selectedVillain.cartaExtra,
            },
            diasActual: diasActual,
            cartaDelMazo: cartaDelMazo,
            progresoActual: progresoActual,
            cardList: cardList
        };
        const deckInfoJSON = JSON.stringify(deckInfoActualizada, null, 2);

        navigate('/Epilogo', { state: { deckInfoJSON, resultado:'derrota' } });
    };

    //Volver a la aventura desde la opción de "ver carta tirano" (por lo que no se resta un día)
    const volverAventuraCartaTirano = () => {
            const deckInfoRecibida = {
                numPlayers: numPlayers,
                gearlocs: gearlocs,
                selectedVillain: {
                    name: selectedVillain.name,
                    keyname: selectedVillain.keyname,
                    encuentrosTirano: selectedVillain.encuentrosTirano,
                    puntosNecesarios: selectedVillain.puntosNecesarios,
                    limiteDias: selectedVillain.limiteDias,
                    clave: selectedVillain.clave,
                    cartaExtra: selectedVillain.cartaExtra,
                },
                diasActual: diasActual,
                cartaDelMazo: cartaDelMazo,
                progresoActual: progresoActual,
                revisarEncuentros: revisarEncuentros,
                objetoNoSubirDia: objetoNoSubirDia,
                objetoEncuentro34Dia: objetoEncuentro34Dia,
                cardList: cardList
            };
            const deckInfoJSON = JSON.stringify(deckInfoRecibida, null, 2);
    
            navigate('/Aventura', { state: { deckInfoJSON } });
      };

    const removeImgTags = (html) => {
        return html.replace(/<img[^>]*>/g, '');
    };

//Para mostrar json, añadir despues del primer <div>:             <pre>{deckInfoJSON}</pre> {/* Muestra el JSON en la pantalla */}
    return (
        <div className="">
            <div className="top-info">
            <Stack direction="row" spacing={1}>
                    <div>
                    <div><Chip icon={<TimelineIcon style={{ color: '#880808' }} />} label="Progreso" className="chip-progreso" /></div>
                        <div className="info-value">{progress} / {limProgress}</div>
                    </div>
                    <div>
                    <div><Chip icon={<LightModeIcon style={{ color: '#FFBF00' }}/>} label="Dias" className="chip-dias" /></div>
                        <div className="info-value">{numDays} / {limDays}</div>
                    </div>
                    <div>
                    <div><Chip icon={<SocialDistanceIcon style={{ color: '#097969' }}/>} label="BQ" className="chip-bq"/></div>
                        <div className="info-value">{bqValue}</div>
                    </div>
                    </Stack>
            </div>

            {verCartaTirano && (
                <div className="seccion-boton-atras">
                <Button variant="contained" onClick={volverAventuraCartaTirano} style={{minHeight: '50px'}}> Volver a la aventura </Button>
                </div>
            )}

            <div className="central-image">
            {selectedVillain && (
                    <>
                        <img
                            src={getVillainImage(imageNumber)}
                            alt={selectedVillain}
                            className="villain-image"
                            style={{ transform: `scale(${zoomLevel})` }}
                            onClick={handleImageClick}
                        />

                        <div class="tools">
                        <Fab className="tool" color="default" aria-label="terms" onClick={expandMiniDoc}>
                                <AutoStoriesIcon id="doc-icon"/>
                        </Fab>
                        <Fab className="tool" color="default" aria-label="remove" onClick={handleImageClick}>
                                <FlipCameraAndroidIcon id="flip-icon"/>
                        </Fab>
                        <Fab className="tool" color="default" aria-label="zoom-in" onClick={handleZoomIn}>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getVillainAudio(imageNumber)}/>

                    </div>
                    </>
                )}
            
            </div>
            <div className="stack-container">
            <div className="avatar-name">
                <Chip color="info" icon={<InfoIcon />} label={selectedVillain.name} onClick={() => handleOpen(selectedVillain.name)} />
            </div>
            </div>

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style} className="modal-rules">

                <Tirano tirano={tiranoName} />

<div className="seccion-boton-cerrar">
                        <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                Cerrar FAQ
                            </Button>
                            </div>
                </Box>
            </Modal>


            <LibroBatalla isExpanded={isMiniDocExpanded} onClose={closeMiniDoc} />


            {!verCartaTirano && (
                <>
                <div className='villain-wrap-controls-container'>
                    <div className="seccion-boton">
                    <Button variant="contained" onClick={volverAventura} style={{minHeight: '50px'}}>Volver</Button> 
                    {showVillainControls && (
                    <div className="villain-controls-container">
                        <div className="villain-controls">
                        <Button variant="contained" onClick={handlePreviousCard} style={{minHeight: '50px'}} disabled={showQuestionBox}> &lt;  </Button>
                        <Button variant="contained" onClick={handleNextCard} style={{minHeight: '50px'}} disabled={showQuestionBox}> &gt; </Button>
                        </div>
                    </div>
                    )}
                        <Button variant="contained" onClick={finalizarAventura} style={{minHeight: '50px'}}>Finalizar aventura </Button>
                    </div>
                </div>
                </>
            )}
            {verCartaTirano && (
                <>
                <div className='villain-wrap-controls-container'>
                    {showVillainControls && (
                    <div className="villain-controls-container">
                        <div className="villain-controls">
                            <Button variant="contained" onClick={handlePreviousCard} style={{minHeight: '50px'}} disabled={showQuestionBox}> &lt;  </Button>
                            <Button variant="contained" onClick={handleNextCard} style={{minHeight: '50px'}} disabled={showQuestionBox}> &gt; </Button>
                      </div>
                    </div>
                    )}
                </div>
                </>
            )}

            {/* Cuadro centrado */}
<Modal
                keepMounted
                open={showQuestionBox}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                </Typography>
                <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                        <p>{questionText}</p>
                        <div className="seccion-boton">
                        <Button variant="contained" onClick={button1Action} style={{minHeight: '50px'}}> {button1Text} </Button>
                        <Button variant="contained" onClick={button2Action} style={{minHeight: '50px'}}> {button2Text} </Button>
                        </div>
                </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default CombateTirano;
