import './EncuentroEspecial.css';
import React, { useEffect, useState } from 'react'; // Importar useState
import { useLocation } from 'react-router-dom';
import { useStateContext } from './StateContext'; // Importa el hook del contexto
import { useNavigate } from 'react-router-dom';
import Audio from './Audio';
import UsoLocal from './UsoLocal';
import Button from '@mui/material/Button';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Fab from '@mui/material/Fab';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

function EncuentroEspecial() {
    const navigate = useNavigate();
    const location = useLocation(); // Hook para obtener el estado de la navegación
    const { deckInfoContext } = location.state || {}; // Obtén la cadena JSON del estado
    const [zoomLevel, setZoomLevel] = useState(1);
    const flipIcon = document.getElementById('flip-icon');
    const ZoomIcon = document.getElementById('zoom-icon');

    const deckInfoEncuentro = deckInfoContext ? JSON.parse(deckInfoContext) : {}; // Parsear JSON si es una cadena

    const { updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress, setDeckInfoContext } = useStateContext(); // Usa el contexto para actualizar el estado

    const { selectedVillain, numPlayers, cardList, cartaDelMazo } = deckInfoEncuentro;
    const diasActual = deckInfoEncuentro.diasActual; // Estado para el número de días
    const [initialized, setInitialized] = useState(false); // Estado para controlar la inicialización

    const progresoActual = deckInfoEncuentro.progresoActual;

    const [showBackImage, setShowBackImage] = useState(false);
    const [encounterSelected, setEncounterSelected] = useState('');
    const [initializedImage, setInitializedImage] = useState(false); // Estado para controlar la inicialización de la imagen
    const [isConfirmed, setIsConfirmed] = useState(false); //Muestra el cuadro que indica que el evento se ha añadido correctamente

    const [deckInfoJSON, setDeckInfoJSON] = useState(deckInfoContext);
    const [subset, setSubset] = useState([]);



    useEffect(() => {
        if (deckInfoEncuentro && !initialized) {
            updateProgress(progresoActual); // Actualiza el progreso inicial
            updateLimProgress(selectedVillain.puntosNecesarios); // Actualiza el límite de progreso
            updateNumDays(diasActual); // Actualiza el número de días
            updateLimDays(selectedVillain.limiteDias); // Actualiza el límite de días
            updateBqValue(diasActual, numPlayers); // Actualiza el valor BQ

            setInitialized(true);
        }

    }, [deckInfoEncuentro, updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress]);


    const handleImageClick = () => {
        // Alterna entre la imagen frontal y la trasera
        setShowBackImage(!showBackImage);
    };

    const getUrlEncuentroEspecial = (encuentroSeleccionado) => {
        return UsoLocal('encuentros-especial%2F'+itemCodeMapping[encuentroSeleccionado]);
    }

    const getEncuentroEspecialImage = (encuentroSeleccionado) => {
        // Usa la imagen trasera si showBackImage es verdadero
        return !showBackImage ? getUrlEncuentroEspecial(encuentroSeleccionado)+'_frontal.jpg?alt=media': getUrlEncuentroEspecial(encuentroSeleccionado)+'_trasera.jpg?alt=media';
    };

    const handleZoomIn = () => {
      setZoomLevel((prevZoomLevel) => {
          const newZoomLevel = prevZoomLevel === 1 ? 1.2 : 1;
          ZoomIcon.style.color = newZoomLevel === 1 ? '#353535' : '#C21807';
          return newZoomLevel;
      });
  };

  const getAventuraAudio = (currentImage) => {
    if (!currentImage) return null;
        
    const audioSrc = currentImage.split('.jpg')[0].replace('/website/', '/website/audio/') + '.mp3';
    return audioSrc;
}

    const itemCodeMapping = {
        'Cazando a los cazadores': 'encuentro_especial_base_1',
        'El portal de Ebonita': 'encuentro_especial_base_2',
        'Vendedores Mohlnor': 'encuentro_especial_base_3',
        'Algo se cuece': 'encuentro_especial_40dias_1',
        'Un diamante en bruto': 'encuentro_especial_40cave_1',
        'Tu eterna recompensa': 'encuentro_especial_40cave_2',
        'El portal de Ebonita (Undertow)': 'encuentro_especial_und_1',
        'Laboratorios del consejo: Investigación y desarrollo': 'encuentro_especial_und_2',
        'Laboratorios del consejo: Peligro biológico': 'encuentro_especial_und_3',
        'Laboratorios del consejo: Fábrica de horrores': 'encuentro_especial_und_4',
        'Una montaña a las afueras de la colina Mohlnor': 'encuentro_especial_und_5',
        'Guía de referencia de aventura Mohlnor': 'encuentro_especial_und_6',
        '¿Señuelos a mi?': 'encuentro_especial_ubk_1',
        'Palos y piedras no pueden romper mis huesos': 'encuentro_especial_ubk_2',
        'Preparando el asalto': 'encuentro_especial_ubk_3',
        'Breaking Baddies': 'encuentro_especial_ubk_4',
        'Confrontamiento Mohlnor': 'encuentro_especial_ubk_5',
        'El portal de Ebonita (Unbreakeable)': 'encuentro_especial_ubk_6',
      };

    const Category = ({ title, items, isOpen, onToggle }) => {
        return (
          <div className="category">
            <div className="category-header" onClick={onToggle}>
              {title}
              <span className="toggle-icon">{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && (
              <div className="items">
                {items.map((item, index) => (
                  <div key={index} className="item" onClick={() => handleSpecialEncounter(item)}>
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      };

      const handleSpecialEncounter = (item) => {
        setShowBackImage(false);
        setEncounterSelected(item);
        setInitializedImage(true);
    }
    
      const [openCategory, setOpenCategory] = useState(null);

      const categories = [
        {
          title: 'Juego base',
          items: [
            'Cazando a los cazadores',
            'El portal de Ebonita',
            'Vendedores Mohlnor',
          ],
        },
        {
          title: '40 días en Daelore',
          items: ['Algo se cuece'],
        },
        {
          title: '40 cuevas en Daelore',
          items: ['Un diamante en bruto', 'Tu eterna recompensa'],
        },
        {
          title: 'Undertow',
          items: [
            'El portal de Ebonita (Undertow)',
            'Laboratorios del consejo: Investigación y desarrollo',
            'Laboratorios del consejo: Peligro biológico',
            'Laboratorios del consejo: Fábrica de horrores',
            'Una montaña a las afueras de la colina Mohlnor',
            'Guía de referencia de aventura Mohlnor',
          ],
        },
        {
          title: 'Unbreakeable',
          items: [
            '¿Señuelos a mi?',
            'Palos y piedras no pueden romper mis huesos',
            'Preparando el asalto',
            'Breaking Baddies',
            'Confrontamiento Mohlnor',
            'El portal de Ebonita (Unbreakeable)',
          ],
        },
      ];

  const handleToggle = (title) => {
    setOpenCategory(openCategory === title ? null : title);
  };


  //FUNCIÓN PARA "BARAJAR" UN MAZO
  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  function updateCardList(deckInfo, itemName) {
    const { diasActual, cardList, cartaDelMazo } = deckInfo;

    // Extraer el subconjunto de cardList desde la posición "diasActual" y agregar la carta seleccionada
    const nuevaCarta = itemCodeMapping[itemName];

    //Respetamos siempre las posiciones de los primeros encuentros 
    let subset = [];
    if(cartaDelMazo>=2){
      subset = cardList.slice(cartaDelMazo + 1);
    }else{
      subset = cardList.slice(3);
    } 

    subset.push(nuevaCarta);

    // "BARAJAMOS el mazo restante"
    const shuffledSubset = shuffleArray(subset);

    // Crear una nueva lista concatenando el principio (sin modificar) y el subconjunto modificado
    let updatedCardList = [];
    if(cartaDelMazo>=2){
      updatedCardList = [
        ...cardList.slice(0, cartaDelMazo + 1), // Elementos antes de diasActual
        ...shuffledSubset // Subconjunto modificado
    ];
    }else{
      updatedCardList = [
        ...cardList.slice(0, 3), // Condición para que se respeten los eventos de los 3 primeros días
        ...shuffledSubset // Subconjunto modificado
    ];
    }

    // Retornar el objeto modificado o actualizar directamente el objeto original
    console.log('nuevo array: ',updatedCardList);
    return {
        ...deckInfo,
        cardList: updatedCardList


    };
  }

  const agregarEncuentro = () => {
    //Esta función tiene que coger las cartas restantes en cardlist (restar las posiciones correspondientes a diasActual), agregar la carta seleccionada a la lista, "barajar" y concatenar con las cartas previas.
    //También deberá actualizar la deckInfo del contexto
    const nuevoCardList = updateCardList(deckInfoEncuentro, encounterSelected);

    // Mostrar el cuadro de confirmación
    setIsConfirmed(true);

    // Ocultar el cuadro de confirmación después de unos segundos
    setTimeout(() => {
        setIsConfirmed(false);
    }, 3000); // 3 segundos (puedes ajustar el tiempo)

    //Actualizar la deckInfo del contexto
    const nuevoDeckInfoJSON = JSON.stringify(nuevoCardList, null, 2);
    setDeckInfoJSON(nuevoDeckInfoJSON);
    setDeckInfoContext(nuevoDeckInfoJSON);

  };

  const volverAventura = () => {
    //Esta función tiene que coger el deckInfo y hacer una función navigate a aventura. Colocar con CSS el botón arriba a la izquierda y añadir una flecha (visual)
    console.log(deckInfoJSON);
    navigate('/Aventura', { state: { deckInfoJSON } });
  };

  return (
    <div className="encuentroEspecialApp">
        <div className="seccion-boton-atras">
            <Button variant="contained" onClick={volverAventura} style={{minHeight: '50px'}}>Volver a la aventura</Button>

        </div>

        <div className="título">
        <h2>Selecciona un encuentro especial</h2>
        </div>

        <div className='listaEncuentroEspeciales'>
            {categories.map((category, index) => (
            <Category
            key={index}
            title={category.title}
            items={category.items}
            isOpen={openCategory === category.title}
            onToggle={() => handleToggle(category.title)}
            />
        ))}
        </div>

        <div className="central-image">
            { initializedImage && (
                <>
                    <img
                        src={getEncuentroEspecialImage(encounterSelected)}
                        alt={encounterSelected}
                        className="encounter-image"
                        onClick={handleImageClick}
                        style={{ transform: `scale(${zoomLevel})` }}

                    />
                    <div class="tools">
                        <Fab className="tool" color="default" aria-label="remove" onClick={handleImageClick}>
                                <FlipCameraAndroidIcon id="flip-icon"/>
                        </Fab>
                        <Fab className="tool" color="default" aria-label="zoom-in" onClick={handleZoomIn}>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getAventuraAudio(getEncuentroEspecialImage(encounterSelected))}/>

                    </div>

                </>
            )}

            {isConfirmed && (
                <div className="confirmation-box">
                    <p>Encuentro añadido correctamente.</p>
                </div>
            )}
        </div>


        
        <div className="seccion-boton">
                <Button variant="contained" onClick={agregarEncuentro} style={{minHeight: '50px'}}>Añadir encuentro al mazo</Button>
            </div>



    </div>
  );
}

export default EncuentroEspecial;