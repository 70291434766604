import React from 'react';
import './Tirano.css'; // Import the CSS file

const Tirano = ({ tirano }) => {
    return (
        <div className="scrollable-content">
            {tirano === 'Nom' && (
                <>
                    <h1>FAQ Nom</h1>
                    <p><strong>Después de que mi primer Gearloc sea K.O., ¿pongo el siguiente de inmediato o al final de la ronda como es normal?</strong><br />
                    El siguiente Gearloc asumirá una posición de inicio en el <span class="important">Battle Mat</span> y el primer lugar en el medidor de iniciativa después del final de la ronda.</p>

                    <p><strong>¿Cómo funciona <span class="important">Thunderclub</span> si el Gearloc está en la esquina y está rodeado?</strong><br />
                    Simplemente lanzado al punto más lejano (otra esquina). Básicamente, la mayoría de las veces te empujará hacia las esquinas opuestas.</p>

                    <p><strong>¿Qué pasa cuando un Gearloc es revivido durante la batalla de <span class="important">Nom</span>?</strong><br />
                    El Gearloc revivido recuperará HP según lo designado por la habilidad, pero no reaparecerá en el Battle Mat al final de la ronda ya que solo puede haber un Gearloc en juego a la vez según las reglas del encuentro. Este Gearloc puede ser devuelto a la batalla después de que otro Gearloc sea K.O.</p>
                </>
            )}

            {tirano === 'Mulmesh' && (
                            <>
                                <h1>FAQ Mulmesh</h1>
                                <p><strong>¿Cuándo debo resolver si Mulmesh tiene 3 HP o menos restantes? ¿Puedo aplicar Veneno, Sangrado, etc. antes de que se vaya (o después de la verificación de HP) para beneficiarme?</strong><br />
                                ¡A elección del jugador! La verificación ocurre al comienzo del turno de Mulmesh, al igual que cualquier efecto como Veneno y Sangrado. Esta es una situación de empate, lo que significa que el orden de resolución depende del jugador. Úsalo a tu favor en la batalla contra Mulmesh.</p>
                            </>
                        )}
            {tirano === 'Marrow' && (
                            <>
                                <h1>FAQ Marrow</h1>
                                <p><strong>¿Cuántos orcos llamará Marrow con su Plan de Respaldo "Call Orc" si lanza 3 <span class="important">Bones</span>?</strong><br />
                                Solo un orco de 5 puntos. Al igual que los Gearlocs, los Baddies y los Tyrants solo pueden usar su Plan de Respaldo una vez por turno. Esto significa que Marrow solo puede usar su Plan de Respaldo máximo de 2 Bones para llamar a un orco de 5 puntos. No puede usar ambos planes de respaldo de 1 Bone y 2 Bones para llamar a un orco de 5 puntos y a un orco de 1 punto.</p>
                            </>
                        )}
            {tirano === 'Goblin King' && (
                <>
                    <h1>FAQ Goblin King</h1>
                    <p><strong>¿La <span class="important">Careless Bomb</span> se golpea a sí misma (sin daño) o solo golpea a la siguiente unidad más fuerte?</strong><br />
                    Se golpea a sí misma pero no se hace daño.</p>

                    <p><strong>Si uso el botín <span class="important">Skinning Knife</span> con el encuentro del tirano 2 <span class="important">King Goblin</span> y el último Baddie derrotado está en la parte superior de la carta de Skinning Knife, ¿qué Baddie uso cuando se activa el encuentro del tirano 2 Goblin King?</strong><br />
                    Sí. Estás poniendo un Baddie en el cuchillo para llevar un registro de qué tipo se sorprende en futuros encuentros. Así que si el Baddie que se elige para ser "desollado" es el último goblin derrotado, se añade al BQ o se baraja en la pila activa cuando sea necesario, como de costumbre.</p>

                    <p><strong>Si necesito sacar un Baddie de 1 punto para el BQ y la pila está vacía, ¿barajo la pila incluyendo los Baddies que están en la parte superior del encuentro del tirano 2 <span class="important">King Goblin</span>?</strong><br />
                    Saca al último goblin derrotado y luego baraja el resto. Este goblin lo usas para el encuentro del tirano 2 King Goblin.</p>                
                </>
            )}
            
            {tirano === 'Duster' && (
                <>
                    <h1>FAQ Duster</h1>
                    <p><strong>¿El tirano <span class="important">Duster</span> puede atacar a un Gearloc en diagonal? Porque puede moverse en diagonal.</strong><br />
    No.</p>

    <p><strong>¿La habilidad de sangrado del tirano <span class="important">Duster</span> se activa incluso si el Gearloc no recibe daño porque usa su dado de defensa?</strong><br />
    Sí, recibes daño incluso si defendiste el ataque.</p>                
                    </>
            )} 

                {tirano === 'Barnacle' && (
                <>
                    <h1>FAQ Barnacle</h1>
                    <p><strong>Si durante un encuentro con el tirano derroto a algún tentáculo, ¿cuando luche contra <span class="important">Barnacle</span> los pongo de nuevo desde la pila de descartes?</strong><br />
    Sí.</p>

    <p><strong>¿Los tentáculos se consideran Baddies del tirano?</strong><br />
    No. Temáticamente sí, pero se consideran un Baddie no tirano para las mecánicas del juego.</p>

    <p><strong>¿Cómo funciona la activación de <span class="important">To the Deep</span>?</strong><br />
    Si en la R1 derroto a un tentáculo y Barnacle entra en la R2. Al final de la R2, activa To the Deep porque lanza Recuperate y ahora tiene un tentáculo en el BQ. Entonces, deja el Battle Mat y regresa solo en la R4, saltándose la R3.</p>

    <p><strong>Si solo Barnacle y mi Gearloc están en el Battle Mat, ¿alguna cara del dado del tirano (Recuperate y Tentacle Wrap) no tiene ningún efecto?</strong><br />
    Podrías tener tentáculos en juego, por lo que podrían tener efecto. Si Barnacle no tiene tentáculos restantes, entonces no, no haría nada.</p>

    <p><strong>Si un tentáculo tiene 2 objetivos y no tiene a nadie adyacente, ¿puede atacar las dos balsas adyacentes vacías?</strong><br />
    No. Ataca la balsa una vez.</p>
                </>
            )} 

            {tirano === 'Kollossum' && (
                <>
                    <h1>FAQ Kollossum</h1>
                    <p><strong>Durante la configuración, ¿pongo primero el Gearloc en el mapa y luego revelo los Baddies debido al texto de la carta de Kollosum "whenever placing units"?</strong><br />
    Sí.</p>

    <p><strong>¿La carta del tirano <span class="important">Stone Perch of The Ashen Summit</span> se activa si juego con 1 Gearloc?</strong><br />
    Sí. Pero no hace diferencia.</p>

    <p><strong>¿Cómo resuelves <span class="important">Synapse</span> de Kollossum, si el "Gearloc más cercano" no tiene espacios adyacentes disponibles?</strong><br />
    El Gearloc más débil no se moverá si no hay espacios adyacentes al Gearloc más cercano. El Gearloc más débil aún lanzará dados de ataque contra el Gearloc más cercano, si puede realizar un ataque a distancia.</p>

                </>
            )} 

{tirano === 'Goblin Queen' && (
                <>
                    <h1>FAQ Goblin Queen</h1>
                    <p><strong>¿El dado de sangrado puede afectar al <span class="important">Guard Goblin</span>?</strong><br />
    Sí, aún toma un turno, por lo que el sangrado lo afectará.</p>

    <p><strong>¿El Guard ataca a cualquier unidad?</strong><br />
    No, solo a la unidad opuesta.</p>

    <p><strong>¿El Guard usa dado de carril e iniciativa?</strong><br />
    No.</p>

    <p><strong>Si el Guard de la <span class="important">Goblin Queen</span> es movido fuera de los lugares A/B (por una habilidad o botín, por ejemplo), ¿cómo se mueve el Guard de la Goblin Queen después?</strong><br />
    Cada vez que el Guard actúa, se moverá un espacio hacia el lugar B. Una vez que llegue a este lugar, continuará moviéndose de B a A y viceversa.</p>

                </>
            )} 

{tirano === "Vol'kesh" && (
                <>
                    <h1>FAQ Volkesh</h1>
                    <p><strong>¿Vol'kesh puede ser afectado por la <span class="important">Poison Armor</span> de Tantrum en la R1 con dos jugadores?</strong><br />
    Sí. Un golpe es un ataque y la Poison Armor es adyacente. Así que si estás adyacente a Vol'kesh cuando ella lanza dados de ataque contra ti, sí funciona.</p>

    <p><strong><span class="important">Set Ablaze</span> dice: las 2 unidades más fuertes reducen su HP en 2. ¿Esto ignora la defensa?</strong><br />
    Sí.</p>

    <p><strong>Si hay 3 unidades con 6 HP (2 Baddies y 1 Gearloc), ¿puedo elegir que ambos Baddies reciban Set Ablaze?</strong><br />
    Sí.</p>
                </>
            )} 

{tirano === 'Nobolous' && (
                <>
                    <h1>FAQ Nobolous</h1>
                    <p><strong>¿<span class="important">Attackycardia</span> no tiene efecto si <span class="important">Nobulous</span> no está en juego?</strong><br />
    Correcto. Si Nobulous no está en juego, esa cara no tiene efecto.</p>

    <p><strong>Cuando la <span class="important">Abomination</span> entra en el <span class="important">Battle Map</span>, ¿la pongo en un lugar normal o en un lugar de mecánico tirando un D6?</strong><br />
    Los Baddies de tipo mecánico tiran un D6.</p>

    <p><strong>¿En qué orden van el dado del tirano y el dado de la abominación?</strong><br />
    En el orden del texto de la carta. La Abomination es un tirano, por lo tanto, se añade a la parte superior (su dado de tirano tiene una instrucción separada).</p>
                </>
            )} 

{tirano === 'Oxide' && (
                <>
                    <h1>FAQ Oxide</h1>
                    <p><strong>Las partes se mueven una vez que se desprenden de <span class="important">Oxide</span>. Se tratan como mechs. Solo una parte puede ser removida por turno, independientemente de cuánto daño se le haya hecho a Oxide (concepto similar a <span class="important">Hardy</span>).</strong></p>
                                    </>
            )} 
        </div>
    );
};

export default Tirano;