import './Faq.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const Faq = () => {
    const navigate = useNavigate();

    const goToMain = () => {
        navigate('/');
    };

    return (
        <div className="faq-container">
            <h2>Preguntas Frecuentes</h2>

            <section className="faq-section">
                <h3>¿Para qué vale esta aplicación?</h3>
                <p>
                    Esta aplicación simplifica la preparación de una partida a Too Many Bones con el contenido existente hasta la fecha. Ayuda a que la experiencia sea más inmersiva a través de unas píldoras de historia y aporta herramientas para facilitar el seguimiento de la partida.
                </p>
            </section>

            <section className="faq-section">
                <h3>¿Cómo se usa?</h3>
                <p>
                    Primero debes escoger a un villano. Puedes seleccionarlo entre cualquiera de las expansiones disponibles. Después debes seleccionar las expansiones que quieres añadir. Esto afecta a las cartas de encuentro que pueden aparecer durante la partida. Por último, escoge el número de jugadores que conforman la partida, esto tiene implicaciones a la hora de seleccionar cartas de encuentro y el cálculo de la BQ.
                </p>
            </section>

            <section className="faq-section">
                <h3>¿Cómo puedo guardar/cargar la partida?</h3>
                <p>
                    Para guardar la partida debes haber escogido un villano y haber comenzado el día 1. Al darle al botón de guardar partida en el menú contextual, se copiará un código en el portapapeles de tu dispositivo que debes guardar en un lugar seguro. <br />
                    Este es el código que se pide en la sección "Cargar partida", es importante pegarlo entre los caracteres <strong>&#123;</strong> y <strong>&#125;</strong> sin añadir ningún texto adicional.
                </p>
            </section>

            <section className="faq-section">
                <h3>Si necesito añadir un encuentro especial durante mi aventura, ¿cómo lo hago?</h3>
                <p>
                    Para añadir un encuentro especial, debes abrir el menú lateral y seleccionar la opción correspondiente. Después aparece la lista de cores/expansiones que contienen encuentros especiales, así como la lista que hay en cada uno. Seleccionas el que necesites, pulsas el botón "Añadir encuentro al mazo" y posteriormente el botón "Volver a la aventura".
                    <br/><br/>
                    Durante la aventura pueden aparecer cartas que contienen otros efectos adicionales a añadir encuentros especiales. Esos aparecerán automáticamente,
                </p>
            </section>

            <section className="faq-section">
                <h3>¿Qué expansiones puedo escoger?</h3>
                <p>
                    Es recomendable utilizar sólo las expansiones de las que dispongas el material original, ya que en ocasiones se requieren tableros y dados específicos que vienen en el contenido de estas expansiones. No obstante aquí van algunas alternativas para complementar tus partidas:
                </p>
                <ul>
                    <li><strong>Si tienes el juego base:</strong> Puedes añadir la expansión 40 días en Daelore que agrega encuentro para el modo solitario y encuentros generales.</li>
                    <li><strong>Si tienes Undertow:</strong> Puedes añadir la expansión 40 olas en Daelore que agrega encuentro para el modo solitario y encuentros generales.</li>
                    <li><strong>Si tienes Unbreakeable:</strong> Puedes añadir la expansión 40 cuevas en Daelore que agrega encuentro para el modo solitario y encuentros generales.</li>
                </ul>
            </section>

            <section className="faq-section">
                <p>
                    La expansión Age of Tyranny es compatible con todo el material, ya que agrega encuentros para los 3 primeros días, añadiendo así más variedad a las cajas base, Undertow y Unbreakeable.
                </p>
                <p>
                    Si seleccionas un villano de la expansión "Rage of Tyranny", van a aparecer encuentros de esta expansión para los primeros días, ya que estos villanos son especiales y en ocasiones así lo requieren.
                </p>
                <p>
                    Si quieres enfrentarte a cualquier otro villano y aumentar la dificultad de la partida, puedes seleccionar "Rage of Tyranny" en la lista de expansiones y existe la probabilidad de que aparezcan al azar algunos de los encuentros de mayor dificultad de esta expansión.
                </p>
                <p>
                    <strong>Nota:</strong> Hay que tener en cuenta que esta aplicación simula el escoger entre todo el material disponible y "baraja" las cartas de encuentro seleccionadas, por lo que hay un factor de azar para que aparezca cierto contenido entre todas las opciones posibles seleccionadas.
                </p>
            </section>

            <div className="seccion-boton">
                <Button variant="contained" onClick={goToMain} style={{minHeight: '50px'}}>Ir a inicio</Button>

            </div>
        </div>
    );
};

export default Faq;


