import React, { createContext, useState, useContext, useEffect } from 'react';

// Crear el contexto
const StateContext = createContext();

// Proveedor del contexto
export function StateProvider({ children }) {
  const [numDays, setNumDays] = useState(0);
  const [limDays, setLimDays] = useState(0);
  const [bqValue, setBqValue] = useState(0);
  const [progress, setProgress] = useState(0); // Nueva variable para el progreso
  const [limProgress, setLimProgress] = useState(0); // Nueva variable para límite del progreso
  const [headerFieldProgressColor, setHeaderFieldProgressColor] = useState(''); //Cambiar el color del progreso (visual)
  const [headerFieldDaysColor, setHeaderFieldDaysColor] = useState(''); //Cambiar el color de los días (visual)
  const [deckInfoContext, setDeckInfoContext] = useState();



  // Función para actualizar el número de días
  const updateNumDays = (days) => setNumDays(days);

    // Función para actualizar el límite de días
    const updateLimDays = (limdays) => setLimDays(limdays);

    const updateProgress = (newProgress) => setProgress(newProgress); // Nueva función para actualizar el progreso
    const updateLimProgress = (newProgress) => setLimProgress(newProgress); // Nueva función para actualizar el progreso

    const updateHeaderFieldProgressColor = (newColor) => setHeaderFieldProgressColor(newColor); // Nueva función para actualizar el progreso
    const updateHeaderFieldDaysColor = (newColor) => setHeaderFieldDaysColor(newColor); // Nueva función para actualizar el progreso

    useEffect(() => {
      if (deckInfoContext) {
      }
  }, [deckInfoContext]);


  // Función para actualizar el valor BQ
  const updateBqValue = (var1, var2) => setBqValue(var1 * var2);

  return (
    <StateContext.Provider value={{ numDays, bqValue, limDays, progress, limProgress, headerFieldProgressColor, headerFieldDaysColor, deckInfoContext, updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress, updateHeaderFieldProgressColor, updateHeaderFieldDaysColor, setDeckInfoContext }}>
      {children}
    </StateContext.Provider>
  );
}

// Hook para usar el contexto
export function useStateContext() {
  return useContext(StateContext);
}
