import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStateContext } from './StateContext';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import OpenAIComponent from './OpenAIComponent';
import Button from '@mui/material/Button';

function Header({ navigationFunctions }) {
  const navigate = useNavigate();
  const { numDays, limDays, bqValue, progress, limProgress, headerFieldProgressColor, headerFieldDaysColor, deckInfoContext } = useStateContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const location = useLocation();
  const [showFields, setShowFields] = useState(false);
  const [showMenuContext, setShowMenuContext] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openAI, setOpenAI] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenIA = () => setOpenAI(true);
  const handleCloseIA = () => setOpenAI(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };

  useEffect(() => {
    if (deckInfoContext) {
      //console.log('Header effect json: ' + deckInfoContext)
      const deckInfoEncuentro = deckInfoContext ? JSON.parse(deckInfoContext) : {};
      //console.log('Header effect: ', deckInfoEncuentro);
    }
    if (location.pathname !== '/' && location.pathname !== '/CargarPartida' && location.pathname !== '/Prologo' && location.pathname !== '/FAQ' ) {
      setShowFields(true);
    } else {
      setShowFields(false);
    }
    if (location.pathname !== '/' && location.pathname !== '/CargarPartida' && location.pathname !== '/Prologo' && location.pathname !== '/FAQ' && location.pathname !== '/EncuentroEspecial' && location.pathname !== '/Epilogo' && location.pathname !== '/Novedades' ) {
      setShowMenuContext(true);
    } else {
      setShowMenuContext(false);
    }

  }, [deckInfoContext, location.pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handlePlayStopMusic = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const encrypt = (text) => {
    const charMap = {
      'a': 'm', 'b': 'n', 'c': 'b', 'd': 'v', 'e': 'c', 'f': 'x', 'g': 'z', 'h': 'l', 'i': 'k', 'j': 'j',
      'k': 'h', 'l': 'g', 'm': 'f', 'n': 'd', 'o': 's', 'p': 'a', 'q': 'q', 'r': 'w', 's': 'e', 't': 'r',
      'u': 't', 'v': 'y', 'w': 'u', 'x': 'i', 'y': 'o', 'z': 'p',
      'A': 'M', 'B': 'N', 'C': 'B', 'D': 'V', 'E': 'C', 'F': 'X', 'G': 'Z', 'H': 'L', 'I': 'K', 'J': 'J',
      'K': 'H', 'L': 'G', 'M': 'F', 'N': 'D', 'O': 'S', 'P': 'A', 'Q': 'Q', 'R': 'W', 'S': 'E', 'T': 'R',
      'U': 'T', 'V': 'Y', 'W': 'U', 'X': 'I', 'Y': 'O', 'Z': 'P',
      '0': '5', '1': '6', '2': '7', '3': '8', '4': '9', '5': '0', '6': '1', '7': '2', '8': '3', '9': '4'
    };

    return text.split('').map(char => charMap[char] || char).join('');
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(text);
    } else {
      // Fallback for older browsers, including some iPhones
      let textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to the bottom of the page
      textarea.style.opacity = '0'; // Hide the textarea from view
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textarea);
    }
  };

  const handleSaveGame = () => {
    toggleMenu();
    const encryptedDeckInfo = encrypt(deckInfoContext);
    copyToClipboard(encryptedDeckInfo);
    setIsSaved(true);
    setTimeout(() => {
      setIsSaved(false);
    }, 6000); // Mostrar aviso por 6 segundos
  };




  const handleEncuentroEspecial = () => {
    toggleMenu();
    navigate('/EncuentroEspecial', { state: { deckInfoContext } });
  };

  const handleCartaTirano = () => {
    toggleMenu();
    const updatedDeckInfoJSON = deckInfoContext;
    const verCartaTirano = true;
    navigate('/CombateTirano', { state: { updatedDeckInfoJSON, verCartaTirano } });
  };

  const handleLoadGame = () => {
    toggleMenu();

    navigate('/CargarPartida');
  };

  const goToFAQ = () => {
    toggleMenu();

    navigate('/FAQ');
  };

  const goToEstadisticas = () => {
    toggleMenu();

    navigate('/Estadisticas');
  };

  const goToNovedades = () => {
    toggleMenu();

    navigate('/Novedades', { state: { deckInfoContext } });
  };

  
  return (
    <header className="header">
      


      <div>
      <div className="music-logo">
        <MusicNoteIcon onClick={handleOpen} style={{ color: '#FFBF00', fontSize: '40px' }} />
        <AutoAwesomeIcon onClick={handleOpenIA} style={{ color: '#add8e6', fontSize: '40px' }} />
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          <iframe style={{ borderRadius: '12px', width: '100%', maxWidth: '600px' }} src="https://open.spotify.com/embed/playlist/1Z2oZG9i3SKyquKDgTVL9C?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </Typography>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openAI}
        onClose={handleCloseIA}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <OpenAIComponent />
          <Button variant="contained" type="submit" onClick={handleCloseIA}>Cerrar</Button>
        </Box>
      </Modal>
    </div>

      <div className="logo">
        <Link to="/">
          <img src="/static/resources%2Flogo.png?alt=media" alt="Logo" />
        </Link>
      </div>



      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <CloseIcon onClick={toggleMenu} className="close-button"/>
        <nav>
          <ul>
            {showFields && showMenuContext && (
              <>
                <li><a href="#" onClick={(e) => { e.preventDefault(); handleEncuentroEspecial(); }}>Añadir encuentro especial</a></li>
                <li><a href="#" onClick={(e) => { e.preventDefault(); handleCartaTirano(); }}>Ver carta del tirano</a></li>
                <li><a href="#" onClick={(e) => { e.preventDefault(); handleSaveGame(); }}>Guardar partida</a></li>
              </>
            )}

            <li><a href="#" onClick={(e) => { e.preventDefault(); handleLoadGame(); }}>Cargar partida</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); goToFAQ(); }}>FAQ</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); goToEstadisticas(); }}>Estadisticas</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); goToNovedades(); }}>Novedades</a></li>

            <li>
            {/*<a href="https://paypal.me/miguelmsa1?country.x=ES&locale.x=es_ES" target="_blank" rel="noopener noreferrer">
              <img src="/static/resources%2Fcoffee.png?alt=media" alt="Café" style={{ width: '20px', marginRight: '8px' }} />
              Invítame a un café
            </a>*/}
          </li>
          </ul>
        </nav>
      </div>

      <MenuIcon onClick={toggleMenu} style={{ color: '#FFBF00', fontSize: '40px', marginRight: '5px' }} />

      {isSaved && (
        <div className="save-notification">
          Partida guardada en el portapapeles, pega el código de guardado en un lugar seguro.
        </div>
      )}
      <audio ref={audioRef} src="/static/resources/background_music_1.mp3" loop />
    

    </header>
    
  );
}

export default Header;
