import './Prologo.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TypingEffect from './TypingEffect'; // Importa el componente TypingEffect
import { useNavigate } from 'react-router-dom';
import Audio from './Audio';
import UsoLocal from './UsoLocal';
import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Button from '@mui/material/Button';
import LightModeIcon from '@mui/icons-material/LightMode';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';


const Prologo = () => {
    const [zoomLevel, setZoomLevel] = useState(1);

    const navigate = useNavigate();
    const [prologoDevuelto, setPrologoDevuelto] = useState('');
    const location = useLocation(); // Hook para obtener el estado de la navegación
    const { deckInfoJSON } = location.state || {}; // Obtén la cadena JSON del estado

    const deckInfo = deckInfoJSON ? JSON.parse(deckInfoJSON) : {}; // Parsear JSON si es una cadena
    console.log("IDPartida: " + deckInfo.id);
    const { selectedVillain } = deckInfo;

    useEffect(() => {
        obtenerPrologo(selectedVillain.name);
    }, [prologoDevuelto]);

    const ZoomIcon = document.getElementById('zoom-icon');

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel === 1 ? 1.2 : 1;
            ZoomIcon.style.color = newZoomLevel === 1 ? '#353535' : '#C21807';
            return newZoomLevel;
        });
    };

    const obtenerPrologo = (villanoSeleccionado) => {
        switch (villanoSeleccionado) {
            case 'Drellen':
                setPrologoDevuelto('  En las profundidades de la maleza de Daelore, la figura imponente de Drellen emerge como una sombra vengativa. Un ser marcado por traiciones y cicatrices de una guerra olvidada, Drellen ha reunido a las criaturas más oscuras bajo su mando, dispuesto a reclamar el reino que alguna vez fue suyo. Los Gearlocs, conscientes del peligro, se preparan para enfrentarse a este tirano, sabiendo que el destino de su hogar depende de su victoria.');
                break;
            case 'Drellen Resentido':
                setPrologoDevuelto('  Drellen Resentido, en su furia renovada, se presenta como una amenaza aún más peligrosa que antes. Su resentimiento alimenta su poder destructivo, y su búsqueda de venganza es implacable. Los Gearlocs deben enfrentar a este adversario con una estrategia meticulosa para evitar ser vencidos por su furia.');
                break;
            case 'Mulmesh':
                setPrologoDevuelto('  Mulmesh, con su furia natural intacta, emerge como una amenaza temida. Su poder y estrategia han aumentado, y su deseo de dominación es palpable. Los Gearlocs deberán unir fuerzas y emplear todas sus habilidades para enfrentarse a este adversario con la fuerza y astucia necesarias para proteger Daelore.');
                break;
            case 'Mulmesh Enojado':
                setPrologoDevuelto('  Mulmesh Enojado ha surgido con una furia aún mayor, dispuesto a desatar su ira destructiva sobre Daelore. Su enojo alimenta su poder, convirtiéndolo en una amenaza formidable. Los Gearlocs deben enfrentar a este adversario intensificado con una estrategia aún más robusta para evitar ser destruidos por su furia.');
                break;
            case 'Nom':
                setPrologoDevuelto('  Nom, la bestia indomable, emerge de las montañas nevadas, trayendo consigo un invierno eterno que amenaza con congelar a Daelore en su furia. Conocido por su sed insaciable de poder y destrucción, Nom avanza con su ejército, destruyendo todo a su paso. Los Gearlocs, la última línea de defensa, deben enfrentarse a este monstruo antes de que su frío mortal consuma el mundo.');
                break;
            case 'Nom Hambrón':
                setPrologoDevuelto('  Nom Hambrón ha emergido con un apetito insaciable, dispuesto a devorar todo lo que encuentra en su camino. Su hambre voraz y su fuerza brutal lo convierten en una amenaza peligrosa para Daelore. Los Gearlocs deben enfrentarse a esta amenaza de manera estratégica para evitar ser consumidos por la furia de Nom.');
                break;
            case 'Gendricks':
                setPrologoDevuelto('  Gendricks, el cruel señor de la guerra, ha levantado un ejército imparable que siembra el terror en los corazones de los habitantes de Daelore. Con su dominio sobre las artes oscuras y su estrategia militar impecable, Gendricks parece invencible. Los Gearlocs saben que deben enfrentarse a él con astucia y fuerza combinadas, pues un enfrentamiento directo podría significar su perdición.');
                break;
            case 'Gendricks que echa humo':
                setPrologoDevuelto('  Gendricks que Echa Humo ha regresado, envuelto en una nube tóxica que emana de su cuerpo. Su habilidad para cubrir el campo de batalla en humo venenoso lo convierte en un adversario peligroso. Los Gearlocs deben superar esta amenaza implacable para evitar ser sofocados por el humo corrosivo.');
                break;
            case 'Rey Goblin':
                setPrologoDevuelto('  El Rey Goblin, una figura astuta y manipuladora, ha reunido a todas las tribus goblins bajo su mando, preparando un asalto sin precedentes sobre Daelore. Con su insaciable ambición de poder, este líder ha desatado un caos que amenaza con desbordar los límites de su propio reino. Los Gearlocs, conscientes del peligro inminente, deben detener al Rey Goblin antes de que su sombra se extienda más allá de lo controlable.');
                break;
            case 'Rey Goblin Encolerizado':
                setPrologoDevuelto('  El Rey Goblin Encolerizado ha regresado con una furia aún más intensa, dispuesto a expandir su dominio con una ferocidad renovada. Su ejército de goblins avanza con rabia y desdén hacia todo lo que encuentran. Los Gearlocs deben enfrentarse a este rey enfurecido con estrategia y valentía para salvar el reino.');
                break;
            case 'Marrow':
                setPrologoDevuelto('  Marrow, el despiadado tirano no-muerto, ha regresado de las profundidades del abismo, decidido a consumir todo a su paso. Con un ejército de espectros y criaturas oscuras, Marrow planea convertir Daelore en un reino de muerte y desolación. Los Gearlocs, conscientes del poder oscuro que enfrenta, se preparan para la batalla final, sabiendo que cualquier error podría significar el fin de todo lo que conocen.');
                break;
            case 'Marrow Resentido':
                setPrologoDevuelto('  Marrow Resentido surge con una furia incontrolable, buscando vengarse de quienes lo han desafiado. Su odio alimenta su poder y lo convierte en una amenaza aún más peligrosa. Los Gearlocs deben enfrentar a Marrow con astucia y fuerza para evitar que su resentimiento destruya Daelore.');
                break;
            case 'Duster':
                setPrologoDevuelto('  Duster, la traidora Gearloc, ha cruzado líneas que nadie imaginaba. Con un odio profundo hacia los suyos, ha formado una alianza con fuerzas oscuras para llevar a cabo su venganza. Los Gearlocs, heridos por su traición, deben detenerla antes de que sus planes destruyan la esperanza de su pueblo. Esta batalla no es solo por Daelore, sino también por el alma de un Gearloc perdido.');
                break;
            case 'Duster Indignada':
                setPrologoDevuelto('  Duster Indignada ha surgido con una rabia incontrolable, buscando vengarse de aquellos que han desafiado su dominio. Su indignación la convierte en una fuerza destructiva, dispuesta a arrasar con todo. Los Gearlocs deben enfrentarse a esta amenaza con cautela para evitar ser destruidos por la furia de Duster.');
                break;
            case 'Barnacle':
                setPrologoDevuelto('  Desde las profundidades del océano, Barnacle, el capitán pirata, ha emergido con su tripulación de seres acuáticos para saquear las costas de Daelore. Con una astucia tan afilada como sus ganchos, Barnacle planea hundir el reino en el caos marino. Los Gearlocs, sabiendo que el mar ya no es seguro, deben enfrentarse a este temible pirata antes de que sus planes arrastren a todo el reino bajo las olas.');
                break;
            case 'Barnacle Iracundo':
                setPrologoDevuelto('  Barnacle Iracundo emerge con una furia renovada, dispuesto a arrasar con todo a su paso. Su ira intensificada y su fuerza brutal lo convierten en una amenaza temible para Daelore. Los Gearlocs deben enfrentarse a este adversario furioso con habilidad y coraje para evitar ser aniquilados.');
                break;
            case 'Kollossum':
                setPrologoDevuelto('  Kollossum, el coloso imponente con fuerza descomunal, ha emergido de las profundidades para desafiar a Daelore con su furia incontrolable. Su presencia se siente como un terremoto, y su misión es clara: destruir todo a su paso para demostrar su supremacía. Los Gearlocs, sabiendo que enfrentarse a Kollossum requiere más que solo fuerza, se preparan para una batalla que probará hasta los límites de su resistencia.');
                break;
            case 'Kollossum Furioso':
                setPrologoDevuelto('  Kollossum Furioso ha emergido con un aumento de poder y rabia, decidido a arrasar con todo en su camino. Su furia desatada lo convierte en una amenaza más peligrosa que nunca. Los Gearlocs deben enfrentarse a esta versión intensificada de Kollossum con una estrategia aún más fuerte para sobrevivir a la tormenta de destrucción.');
                break;
            case 'Reina Goblin':
                setPrologoDevuelto('  La Reina Goblin, astuta y despiadada, gobierna con puño de hierro desde las sombras. Con un ejército de secuaces a sus órdenes, ha tramado planes para extender su dominio sobre Daelore, amenazando con sumir el reino en un caos perpetuo. Los Gearlocs, conscientes de la creciente amenaza, se adentran en sus dominios, sabiendo que un error podría significar el fin de su hogar.');
                break;
            case 'Reina Goblin Encolerizada':
                setPrologoDevuelto('  La Reina Goblin Encolerizada ha tomado el campo de batalla con una furia aún mayor, buscando vengarse de quienes se atreven a desafiar su dominio. Su enojo la hace aún más peligrosa, y su ejército goblin avanza con renovada ferocidad. Los Gearlocs deben enfrentarse a esta amenaza enardecida con una estrategia decisiva para salvar Daelore.');
                break;
            case 'Nobulous':
                setPrologoDevuelto('  Nobulous, el enigmático y temido señor de la oscuridad, ha despertado de su largo letargo, trayendo consigo una ola de terror y desesperación. Su dominio sobre las sombras y su habilidad para manipular las mentes lo convierten en una amenaza formidable para Daelore. Los Gearlocs deben enfrentarse a este ser oscuro, cuyo poder pone a prueba no solo su fortaleza, sino también su resistencia mental.');
                break;
            case 'Nobulous Cascarrabias':
                setPrologoDevuelto('  Nobulous Cascarrabias ha emergido con un temperamento aún más volátil, buscando desatar su ira sobre Daelore. Su carácter explosivo y su poder oscuro lo convierten en una amenaza temible. Los Gearlocs deben enfrentarse a este adversario intensificado con habilidad y coraje para salvar el reino de su furia.');
                break;
            case 'Vol\'kesh':
                setPrologoDevuelto('  Vol\'kesh, la despiadada maestra de las tormentas, ha llegado para desatar su furia sobre Daelore. Con el poder de manipular los elementos a su voluntad, su amenaza es tan destructiva como implacable. Los Gearlocs, enfrentando tormentas y cataclismos, deben encontrar una forma de detener a este ser elemental antes de que el reino se convierta en un campo de ruinas.');
                break;
            case 'Vol\'Kesh Irritada':
                setPrologoDevuelto('  Vol\'Kesh Irritada ha emergido con un aumento en su furia destructiva, dispuesto a desatar su enojo sobre Daelore. Su poder oscuro y su rabia lo convierten en una amenaza formidable. Los Gearlocs deben enfrentar a esta versión intensificada del villano con habilidad y estrategia para salvar el reino.');
                break;
            case 'Autómata':
                setPrologoDevuelto('  Autómata, el mecánico imparable, ha salido de sus instalaciones subterráneas con una precisión letal. Su ejército de máquinas y su habilidad para adaptar sus estrategias a cada situación lo convierten en una amenaza imponente. Los Gearlocs, enfrentando una combinación de acero y astucia, deben encontrar la forma de superar a este enemigo casi indestructible.');
                break;
            case 'Rok & Rol':
                setPrologoDevuelto('  Rok & Rol, la dupla destructiva, se ha unido para sembrar el caos en Daelore con su combinación única de fuerza bruta y estrategia despiadada. Su objetivo es derribar todo lo que se interponga en su camino, y los Gearlocs deben enfrentarse a ellos en una batalla que desafía tanto su resistencia como su ingenio.');
                break;
            case 'Cinder':
                setPrologoDevuelto('  Cinder, el devastador incendiario, ha puesto en marcha su plan para reducir Daelore a cenizas. Su control sobre el fuego y su deseo de destrucción lo convierten en una amenaza imparable. Los Gearlocs, enfrentando un infierno literal, deben combatir contra la furia de Cinder para salvar su hogar de una conflagración total.');
                break;
            case 'Domina':
                setPrologoDevuelto('  Domina, la maestra del control mental, ha comenzado a tejer una red de manipulación y sumisión en Daelore. Con su habilidad para controlar las mentes, sus planes de dominación parecen casi imbatibles. Los Gearlocs deben enfrentar no solo a un enemigo físico, sino a las fuerzas invisibles de la mente que amenazan con desmoronar su resistencia.');
                break;
            case 'Gavenkog':
                setPrologoDevuelto('  Gavenkog, el temido señor de los venenos, ha lanzado una plaga mortal sobre Daelore. Su dominio sobre las toxinas y su habilidad para propagar el caos químico lo convierten en una amenaza que afecta tanto a los vivos como a los ecosistemas. Los Gearlocs deben encontrar una manera de contrarrestar su veneno antes de que el reino se convierta en un desierto envenenado.');
                break;
            case 'Nexus':
                setPrologoDevuelto('  Nexus, el coloso de energía arcana, ha emergido de su escondite para desatar una ola de destrucción mágica sobre Daelore. Su habilidad para manipular la energía a niveles devastadores lo convierte en una amenaza de magnitudes épicas. Los Gearlocs deben enfrentarse a este titán mágico con inteligencia y coordinación para evitar la aniquilación total.');
                break;
            case 'Leech':
                setPrologoDevuelto('  Leech, el parásito voraz, ha comenzado a drenar la vitalidad de Daelore con su capacidad para absorber la vida misma. Su presencia es una amenaza que se extiende rápidamente, poniendo en peligro no solo la tierra, sino también a sus habitantes. Los Gearlocs deben encontrar una manera de enfrentarse a esta amenaza parasitaria antes de que el reino se consuma por completo.');
                break;
            case 'Amanight':
                setPrologoDevuelto('  Amanight, el maestro de la oscuridad nocturna, ha traído consigo una noche interminable que envuelve Daelore en sombras aterradoras. Su capacidad para manipular la oscuridad y los horrores nocturnos crea un desafío temible para los Gearlocs. Deben encontrar una manera de derrotar a este maestro de la noche antes de que la oscuridad consuma todo.');
                break;
            case 'Oxide':
                setPrologoDevuelto('  Oxide, el devastador de la corrosión, ha comenzado a propagar su miasma corrosivo por Daelore, destruyendo todo a su paso con su poder corrosivo. Su presencia amenaza con desintegrar tanto a los recursos naturales como a los defensores del reino. Los Gearlocs deben enfrentarse a esta amenaza destructiva y encontrar una forma de detener la corrosión antes de que sea demasiado tarde.');
                break;
            case 'Bat':
                setPrologoDevuelto('  Bat, el monstruo adaptable, se presenta en múltiples formas, cada una más desafiante que la anterior. Su capacidad para cambiar y evolucionar lo convierte en un adversario impredecible. Los Gearlocs deben prepararse para un combate que varía en dificultad y estrategia, enfrentandose a Bat en sus diversas formas.');
                break;
            case 'Blobulous':
                setPrologoDevuelto('  Blobulous, el monstruo viscoso y amorfo, ha comenzado su avance de desintegración por Daelore, convirtiendo todo en una masa de slime. Su capacidad para absorber y corromper lo que toca lo convierte en un adversario temible. Los Gearlocs deben encontrar una manera de detener a Blobulous antes de que el reino se transforme en un paisaje grotesco.');
                break;
            case 'Locgear':
                setPrologoDevuelto('  Locgear, el líder implacable de las máquinas, ha traído su ejército de autómatas para conquistar Daelore con precisión calculada. Su maestría en la ingeniería mecánica lo convierte en una amenaza formidable. Los Gearlocs deben enfrentarse a su estrategia meticulosa y a sus máquinas letales para evitar que el reino caiga bajo el dominio mecánico.');
                break;
            default:
                setPrologoDevuelto('  Error en el prólogo');
                break;
        }
    };

    let typingEffectRef = null;

    const handleImageClick = () => {
        if (typingEffectRef) {
            typingEffectRef.handleClick();
        }
    };

    const avanzarEncuentro = () => {
        navigate('/Aventura', { state: { deckInfoJSON } });
    };

    const getPrologoAudioTexto = () => {
        
        const audioSrc = UsoLocal('audio/prologos/prologos_'+selectedVillain.keyname + '.mp3');
        return audioSrc;
    }

    return (
        <div className="">
            <div className="central-image" style={{ position: 'relative'}} onClick={handleImageClick}>
                <img
                    src={UsoLocal('epilogos%2Fepilogo_vacio.jpg?alt=media')}
                    alt={'prólogo de la historia'}
                    className="villain-image"
                    style={{ marginTop: '10px', width: '100%', height: 'auto', transform: `scale(${zoomLevel})` }} // Ajusta el estilo según sea necesario
                />

                <div class="tools">
                        <Fab className="tool" color="default" aria-label="remove" disabled>
                                <FlipCameraAndroidIcon id="flip-icon"/>
                        </Fab>
                        <Fab className="tool" color="default" aria-label="zoom-in" disabled>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getPrologoAudioTexto()} />
                </div>
                <div className='titulo-prologo'>
                    <h2>Prólogo</h2>
                </div>
                <TypingEffect
                    text={prologoDevuelto}
                    speed={50}
                    ref={(ref) => typingEffectRef = ref} // Pasa la referencia
                />
            </div>
            <div className="seccion-boton">
                <Button className='boton-aventura' onClick={avanzarEncuentro} variant="contained" startIcon={<LightModeIcon />}>
                    <span className='boton-aventura-text'>Primer día</span>
                </Button>
            </div>
        </div>
    );
};

export default Prologo;

