import React, { useEffect, useRef } from 'react';
import Fab from '@mui/material/Fab';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const Audio = ({ audiosrc }) => {
    const audioRef = useRef(null);
    const playPauseButtonRef = useRef(null);
    const iconRef = useRef(null);

    useEffect(() => {
        if (!audiosrc) return;

        const audio = audioRef.current;
        const icon = iconRef.current;
        icon.style.color = '#353535';
        
        const handlePlayPause = () => {
            audio.currentTime = 0;

            if (audio.paused) {
                audio.play();
                console.log('Playing audio');
                icon.style.color = '#1DB954';
            } else {
                audio.pause();
                icon.style.color = '#353535';
            }
        };

        const playPauseButton = playPauseButtonRef.current;
        playPauseButton.addEventListener('click', handlePlayPause);

        return () => {
            playPauseButton.removeEventListener('click', handlePlayPause);
        };
    }, [audiosrc]);

    if (!audiosrc) {
        return (
            <div>
                No hay audio disponible
            </div>
        );
    }

    return (
        <div>
            <audio ref={audioRef} src={audiosrc}></audio>
            <Fab className="tool" color="default" aria-label="remove" ref={playPauseButtonRef}>
                <VolumeUpIcon ref={iconRef} />
            </Fab>
        </div>
    );
};

export default Audio;