import React, { useState } from 'react'; // Importar useState
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import './PuntosEntrenamiento.css';
import Button from '@mui/material/Button';
import Gearloc from './FAQ/Gearloc';

const PuntosEntrenamiento = ({ gearlocs, setGearlocs }) => {    
    const [open, setOpen] = useState(false); // Initialize the open state to false
    const [gearlocName, setGearlocName] = useState(''); // Initialize the gearlocName state

   const style = {
        position: 'absolute',
        top: '10%', // Position the Box 10% from the top of the viewport
        left: '50%',
        transform: 'translateX(-50%)', // Center horizontally
        width: '90vw', // Set the width to 90% of the viewport width
        maxWidth: '90vw', // Ensure the maximum width is 90% of the viewport width
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto', // Enable vertical scrolling
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (gearlocName) => {
        setGearlocName(gearlocName);
        setOpen(true);
    };

    const increaseEntrenamiento = (index) => {
        const newGearlocs = [...gearlocs];
        newGearlocs[index].entrenamiento += 1;
        setGearlocs(newGearlocs);
    };

    const decreaseEntrenamiento = (index) => {
        const newGearlocs = [...gearlocs];
        newGearlocs[index].entrenamiento = Math.max(0, newGearlocs[index].entrenamiento - 1);
        setGearlocs(newGearlocs);
    };

    return (
        <div className="stack-container">
            {gearlocs.map((gearloc, index) => (
                <div key={index} className="arrows-container">
                    <ArrowBackIosIcon className="arrows" onClick={() => decreaseEntrenamiento(index)} />
                    <div className="avatar-container" key={index}>
                        <div className="avatar-wrapper">
                            <Avatar
                                alt={gearloc.name}
                                src={gearloc.src}
                                sx={{ width: 120, height: 120, opacity: 0.5 }}
                            />
                            <div className="avatar-number">{gearloc.entrenamiento}</div> {/* Display the integer variable */}
                        </div>
                        <div className="avatar-name">
                        <Chip color="info" icon={<InfoIcon />} label={gearloc.name} onClick={() => handleOpen(gearloc.name)} />
                        </div>
                    </div>
                    <ArrowForwardIosIcon className="arrows" onClick={() => increaseEntrenamiento(index)} />
                </div>
            ))}

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style} className="modal-rules">

                <Gearloc gearloc={gearlocName} />

<div className="seccion-boton-cerrar">
                        <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                Cerrar FAQ
                            </Button>
                            </div>
                </Box>
            </Modal>
        </div>
    );
};

export default PuntosEntrenamiento;