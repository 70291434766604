import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import './TypingEffect.css'; // Importa el archivo CSS

const TypingEffect = forwardRef(({ text = '', speed = 100 }, ref) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isTyping, setIsTyping] = useState(true);
    const [typingFinished, setTypingFinished] = useState(false); // Nuevo estado para saber si la escritura ha terminado

    useEffect(() => {
        if (typingFinished) {
            setDisplayedText(text); // Muestra el texto completo si la escritura ha terminado
            return;
        }

        if (!isTyping || !text) return; // Asegúrate de que 'text' no sea undefined o null

        let index = 0;
        const intervalId = setInterval(() => {
            setDisplayedText((prev) => prev + text[index]);
            index += 1;
            if (index >= text.length) { // Ajusta la condición para que incluya todo el texto
                clearInterval(intervalId);
                setIsTyping(false);
                setTypingFinished(true); // Indica que la escritura ha terminado
            }
        }, speed);

        return () => clearInterval(intervalId); // Limpia el intervalo si el componente se desmonta
    }, [text, speed, isTyping, typingFinished]);

    const handleClick = () => {
        setDisplayedText(text); // Muestra el texto completo cuando se hace clic
        setIsTyping(false); // Detiene el efecto de escritura
        setTypingFinished(true); // Marca el efecto como terminado
    };

    useImperativeHandle(ref, () => ({
        handleClick, // Expone el método handleClick
    }));

    return (
        <div className="typing-effect-container">
            <textarea
                value={displayedText}
                readOnly
                className="typing-effect-textarea"
            />
        </div>
    );
});

export default TypingEffect;

