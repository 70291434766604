import React, { useState } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import axios from 'axios';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    height: 120px !important;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    /* firefox */
    &:focus-visible {
      outline: 0;
    }
  `,
);

const OpenAIComponent = () => {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('input:', input);
        const result = await axios.post('https://tmbadventures.es/api/openai', { input });
        
        const data = result.data;
        console.log(data);

        setResponse(data.choices[0].message.content);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft: '16px', marginRight: '16px' }}>
        <Chip label="Beta 0.1" color="primary" />
        <h2>Too Many Bones Asistente IA</h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
                <Textarea
                    maxRows={4}
                    placeholder="Hola, soy tu asistente de Too Many Bones. ¿En qué puedo ayudarte?"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                />
            </div>
            <div className="button-group">
            <Button variant="contained" type="submit">Enviar</Button> 
            </div>

        </form>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <p>{response}</p>
        </div>
        </div>
    );
};

export default OpenAIComponent;