import React from 'react';
import './PlayerSelector.css';
import { IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Chip from '@mui/material/Chip';

const PlayerSelector = ({ numPlayers, onIncrease, onDecrease }) => {
    return (
        <div className="player-selector">
            <Fab onClick={onDecrease} color="primary" aria-label="remove">
                <RemoveIcon className="player-selector-button"/>
            </Fab>
            <input
                type="hidden"
                value={numPlayers}
                readOnly
                className="player-selector-input"
            />
            <Chip label={numPlayers} className="player-selector-chip" readOnly/>
            <Fab onClick={onIncrease} color="primary" aria-label="add">
                <AddIcon className="player-selector-button"/>
            </Fab>
        </div>
        
    );
};

export default PlayerSelector;
