import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SelectorMenu from './SelectorMenu';
import Aventura from './Aventura';
import Header from './Header'; // Importa el componente Header
import { StateProvider } from './StateContext'; // Importa el proveedor de estado
import CombateTirano from './CombateTirano';
import EncuentroEspecial from './EncuentroEspecial';
import CargarPartida from './CargarPartida';
import Prologo from './Prologo';
import Epilogo from './Epilogo';
import Faq from './Faq';
import Novedades from './Novedades';
import Estadisticas from './Estadisticas';
import RevisarProximosEncuentros from './RevisarProximosEncuentros';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactGA from "react-ga4";


function App() {
    ReactGA.initialize("G-6RLLR2BEB6");

    const theme = createTheme({
        shadows: ["none"]
      });
      
  // Calcula el valor de BQ
  const calculateBQ = (var1, var2) => var1 * var2;

    return (
        <ThemeProvider theme={theme}>
        <div>

            <StateProvider>
            <Router >{/* basename="/tmbcards" */}
            <Header 
                numDays={0} 
                bqValue={calculateBQ(0, 0)}
            /> 
                <Routes>
                    <Route path="/Aventura" element={<Aventura />} />
                    <Route path="/CombateTirano" element={<CombateTirano />} />
                    <Route path="/" element={<SelectorMenu />} />
                    <Route path="/EncuentroEspecial" element={<EncuentroEspecial />} />
                    <Route path="/CargarPartida" element={<CargarPartida />} />
                    <Route path="/Prologo" element={<Prologo />} />
                    <Route path="/Epilogo" element={<Epilogo />} />
                    <Route path="/FAQ" element={<Faq />} />
                    <Route path="/Novedades" element={<Novedades />} />
                    <Route path="/Estadisticas" element={<Estadisticas />} />
                    <Route path="/RevisarProximosEncuentros" element={<RevisarProximosEncuentros />} />
                </Routes>
            </Router>
            </StateProvider>
        </div>
        </ThemeProvider>
    );
}

export default App;
