import React, { useEffect, useState } from 'react';
import './CargarPartida.css';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from './StateContext'; // Importa el hook del contexto

const CargarPartida = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { setDeckInfoContext } = useStateContext(); // Usa el contexto para actualizar el estado


  function decrypt(text) {
    const charMap = {
      'm': 'a', 'n': 'b', 'b': 'c', 'v': 'd', 'c': 'e', 'x': 'f', 'z': 'g', 'l': 'h', 'k': 'i', 'j': 'j',
      'h': 'k', 'g': 'l', 'f': 'm', 'd': 'n', 's': 'o', 'a': 'p', 'q': 'q', 'w': 'r', 'e': 's', 'r': 't',
      't': 'u', 'y': 'v', 'u': 'w', 'i': 'x', 'o': 'y', 'p': 'z',
      'M': 'A', 'N': 'B', 'B': 'C', 'V': 'D', 'C': 'E', 'X': 'F', 'Z': 'G', 'L': 'H', 'K': 'I', 'J': 'J',
      'H': 'K', 'G': 'L', 'F': 'M', 'D': 'N', 'S': 'O', 'A': 'P', 'Q': 'Q', 'W': 'R', 'E': 'S', 'R': 'T',
      'T': 'U', 'Y': 'V', 'U': 'W', 'I': 'X', 'O': 'Y', 'P': 'Z',
      '5': '0', '6': '1', '7': '2', '8': '3', '9': '4', '0': '5', '1': '6', '2': '7', '3': '8', '4': '9'
    };
  
    return text.split('').map(char => charMap[char] || char).join('');
  }

  useEffect(() => {
    const isValid = checkValidCode(inputValue);
    if (isValid) {
    console.log('El código es válido');
    setIsValid(true);
    // Lógica para cargar la partida
    } else {
    console.log('El código es inválido');
    setIsValid(false);
    // Mostrar un mensaje de error o manejarlo de alguna manera
    }
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };



  const checkValidCode = (encryptedCode) => {
    try {
      // Desencripta el código
      const decryptedCode = decrypt(encryptedCode);
  
      // Intenta parsear el JSON
      const parsedData = JSON.parse(decryptedCode);
  
      // Validar la estructura básica
      if (typeof parsedData !== 'object' || parsedData === null) return false;
  
      // Verificar propiedades principales
      if (
        typeof parsedData.numPlayers !== 'number' ||
        typeof parsedData.selectedVillain !== 'object' ||
        typeof parsedData.diasActual !== 'number' ||
        typeof parsedData.progresoActual !== 'number' ||
        !Array.isArray(parsedData.cardList)
      ) {
        return false;
      }
  
      // Verificar las propiedades del villano
      const villain = parsedData.selectedVillain;
      if (
        typeof villain.name !== 'string' ||
        typeof villain.keyname !== 'string' ||
        typeof villain.encuentrosTirano !== 'number' ||
        typeof villain.puntosNecesarios !== 'number' ||
        typeof villain.limiteDias !== 'number' ||
        typeof villain.clave !== 'string' ||
        typeof villain.cartaExtra !== 'number'
      ) {
        return false;
      }
  
      // Si todas las validaciones pasan, retorna true
      return true;
    } catch (error) {
      // Si algo falla, como un JSON inválido, retorna false
      return false;
    }
  };

  const handleLoadGame = () => {
    if(isValid){
        const deckInfoJSON = decrypt(inputValue);
        setDeckInfoContext(deckInfoJSON);
        navigate('/Aventura', { state: { deckInfoJSON } });
    }
    // Lógica para cargar el juego usando el valor desencriptado
    // Ejemplo: navegar a otra página después de cargar la partida
    // navigate('/your-next-route');
  };

  return (
    <div className='app'>
      <div className={`message-box ${isValid ? 'valid' : 'invalid'}`}>
        {isValid ? 'El código es válido' : 'El código no es válido'}
      </div>
      <label htmlFor="save-code">Introduce el código de guardado:</label>
      <textarea
        id="save-code"
        value={inputValue}
        onChange={handleInputChange}
        placeholder='Introduce el código aquí... Ejemplo: {"dtfAgmocwe": 6,"ecgcbrcvYkggmkd": {"dmfc":"Vwcggcd","hcodmfc": "vwcggcd"}}'
      />
      <button onClick={handleLoadGame}>Cargar Partida</button>
    </div>
  );
}

export default CargarPartida;

