import React from 'react';
import './Gearloc.css'; // Import the CSS file

const Gearloc = ({ gearloc }) => {
    return (
        <div className="scrollable-content">
            {gearloc === 'Boomer' && (
                <>
                    <h1>FAQ Boomer</h1>
                    <p><strong>¿Pueden las <span className="important">granadas de fragmentación</span> de Boomer apuntar a un espacio en el <span className="important">Battle Mat</span>, en lugar de a un <span className="important">Baddie</span>?</strong><br />
                    Sí. Las granadas de fragmentación son la excepción. Todos los demás tipos de granadas necesitan un objetivo válido para ser lanzadas.</p>

                    <p><strong>¿Qué hace el símbolo no listado en el dado <span className="important">Search for 325</span> de Boomer?</strong><br />
                    Obtienes el dado consumible <span className="important">Holy Hand Grenade</span> (colócalo en tu mat).</p>

                    <p><strong>¿Cómo funciona la habilidad <span className="important">Napalm</span> de Boomer? ¿Siempre recibirá <span className="important">Backlash</span> (daño por la habilidad Napalm)?</strong><br />
                    El Napalm de Boomer es un dado de habilidad que se lanza, y luego actúa como un contador. Supongamos que lo lanzas y sale 2x3. Colocarías el dado en el Baddie objetivo, y al inicio del turno del Baddie, el Baddie recibiría 2 de daño. El dado luego bajaría a 2x2. Al inicio del turno del Baddie en la siguiente ronda, recibiría 2 de daño, y el dado bajaría a 2x1. Al inicio de su siguiente turno, el Baddie recibiría otros 2 de daño, y luego el dado se agotaría. El Backlash solo afectará a Boomer si esta es la cara que sale cuando se lanza el dado. El contador no bajará a la cara de Backlash.</p>

                    <p><strong>¿Puedo usar las granadas de Boomer para apuntar a ella misma incluso con la palabra clave <span className="important">Guarded</span>?</strong><br />
                    Sí. Cualquier cosa que no apunte puede golpearla.</p>

                    <p><strong>¿Cómo funciona <span className="important">Smoke</span>?</strong><br />
                    El Baddie atacante no se considera que haya infligido ningún daño a efectos de cualquier efecto que se active "al infligir daño".</p>
                </>
            )}

            {gearloc === 'Tantrum' && (
                <>
                    <h1>FAQ Tantrum</h1>
                    <p><strong>¿Puedo lanzar solo mis dados de defensa y dados de habilidad para no aumentar el dado de <span class="important">Rage</span>?</strong><br />
                    Sí.</p>

                    <p><strong>¿Puedo lanzar <span class="important">Attack Mods</span> sin un objetivo?</strong><br />
                    ¡Sí! Si no usa la palabra objetivo, no necesita un objetivo.</p>

                    <p><strong>¿Puedo usar <span class="important">Axe Collector</span> para infligir 2 de daño a un <span class="important">Baddie</span> <span class="important">Untargetable</span>?</strong><br />
                    Sí.</p>

                    <p><strong>¿Puede Tantrum activar su dado de habilidad <span class="important">Lashback</span> si un Baddie lo golpea por 1 y él bloquea con 1 escudo?</strong><br />
                    Sí. Pero no puedes usarlo si de alguna manera eres dañado por un Baddie que no lanza ataque contra ti. Los Baddies y los Gearlocs pueden golpear con éxito sin que la unidad opuesta reciba daño, activando el Lashback de Tantrum, pero no activando el Lashback de los Baddies.</p>

                    <p><strong>¿Cómo funcionan <span class="important">Poison Armor</span> y <span class="important">Lashback</span>?</strong><br />
                    Este efecto se activa cuando eres golpeado (es decir, un Baddie lanza dados de ataque contra ti y te inflige al menos 1 de daño). No se activa por daño que no sea de ataque, pero sí se activa si mitigas el daño (por ejemplo, usando defensa o HP de refuerzo).</p>

                    <p><strong>¿Puede Tantrum activar su dado de habilidad <span class="important">Lashback</span> si el dado de ataque del Baddie lanza un <span class="important">bone</span> sin efecto? ¿Cuenta como golpe?</strong><br />
                    No. Un 'golpe' es un ataque exitoso (dados de ataque que infligen al menos 1 de daño).</p>

                    <p><strong>¿Aumenta el medidor de <span class="important">Rage</span> de Tantrum cuando recibe daño usando <span class="important">Buff HP</span>?</strong><br />
                    No. Buff HP es diferente del HP estándar y no afecta el medidor de Rage de Tantrum cuando se pierde.</p>

                    <p><strong>¿Puedo usar <span class="important">Rage 1.8</span>, <span class="important">Axe Collector</span> o <span class="important">Body Count</span> antes de lanzar mis dados de ataque?</strong><br />
                    Sí. Puedes usarlos en cualquier momento durante tu turno.</p>

                    <p><strong>¿Qué nivel de <span class="important">Execute Rage</span> uso para un Baddie de 3 puntos?</strong><br />
                    T2 o T3.</p>
                </>
            )}

            {gearloc === 'Picket' && (
                <>
                <h1>FAQ Picket</h1>

                <p><strong>¿Se puede usar <span class="important">Riposte</span> para evitar las habilidades de los <span class="important">Baddies</span> que se activan cuando se lanzan <span class="important">Bones</span>?</strong><br />
    Sí. Las habilidades de los Baddies que se activan cuando se lanzan Bones (Planes de Respaldo de Baddies) se consideran habilidades y se pueden evitar usando Riposte.</p>

    <p><strong>¿Se puede usar <span class="important">Riposte</span> para evitar las habilidades de los <span class="important">Tyrants</span>?</strong><br />
    Sí. Las habilidades de los Tyrants se pueden evitar.</p>

    <p><strong>¿Cómo funciona <span class="important">Riposte</span>?</strong><br />
    El Baddie atacante no se considera que haya infligido ningún daño a efectos de cualquier efecto que se active "al infligir daño".</p>

    <p><strong>¿Los dados de <span class="important">Def</span> en las ranuras bloqueadas de Picket para su <span class="important">Innate +1</span> reducen su Def disponible para lanzar?</strong><br />
    No. Esta es una gran parte de lo que hace que el Innate +1 de Picket sea increíble.</p>

    <p><strong>¿Puedo usar el botín <span class="important">Reinforced Buckler</span> para aumentar los dados que se lanzaron con <span class="important">Innate Shield Wall</span>?</strong><br />
    No. Este Innate no se usa en tu turno.</p>

    <p><strong>¿Puedo usar <span class="important">Raider Armor</span> para aumentar los dados que se lanzaron con <span class="important">Innate Shield Wall</span>?</strong><br />
    No.</p>

    <p><strong>¿Puedo lanzar el dado de daño constante si no tengo objetivo?</strong><br />
    Sí. Además, el daño constante funciona incluso si no atacas a tu objetivo o si tus ataques obtienen Bones. 0 + 1 = 1.</p>

    <p><strong>¿Importa el orden de resolución de <span class="important">Def</span> cuando se usa <span class="important">Constant Defense</span> en mis ranuras bloqueadas?</strong><br />
    No. Resuelve toda la defensa en el orden que desees. Típicamente, usar Constant Defense primero es la mejor jugada.</p>

    <p><strong>¿El Plan de Respaldo "Shield Bash" de Picket agota los dados estándar de <span class="important">Atk</span> y <span class="important">Def</span> y los dados de habilidad?</strong><br />
    Solo los dados de habilidad. Los dados de Atk y Def se eliminan, pero no se agotan.</p>

    <p><strong>¿Funciona <span class="important">Sword Advance</span> - <span class="important">Constant Damage</span>?</strong><br />
    Esto se aplica incluso si no se inflige daño (aumentando el daño infligido a 1).</p>
                </>
            )}

            {gearloc === 'Patches' && (
                <>
                <h1>FAQ Patches</h1>
                <p><strong>¿Necesita Patches estar adyacente a otro Gearloc para curarlo?</strong><br />
    No. Patches puede lanzar sus habilidades de curación por todo el <span class="important">Battle Mat</span>, no necesita estar adyacente.</p>

    <p><strong>¿Puede Patches curar uno de los bots de Tink?</strong><br />
    No. Patches solo puede curar Gearlocs; no puede curar aliados como las mascotas de Ghillie, los bots de Tink o Nightshade. Por eso sus habilidades de curación especifican "curar Gearloc".</p>
                </>
            )}

            {gearloc === 'Nugget' && (
                <>
                <h1>FAQ Nugget</h1>
                <p><strong>¿Los <span class="important">Slingstones</span> de Nugget cuestan <span class="important">Dex</span>? ¿Necesito un Slingstone por cada dado de <span class="important">Atk</span>, o solo uno para todo el ataque?</strong><br />
    Los Slingstones en sí no cuestan Dex, pero los dados de Atk que te permiten usar sí. Ejemplo: Para lanzar 2 dados de Atk como un ataque a distancia, necesitarás al menos 2 Atk, 2 Dex y 2 Slingstones.</p>

    <p><strong>¿Es <span class="important">Longblade</span> una habilidad de cuerpo a cuerpo o a distancia?</strong><br />
    A distancia. Usa Longblade por sí sola para un ataque a distancia. Si se usa junto con dados de Atk estándar, también se deben usar Slingstones para hacer el ataque a distancia.</p>

    <p><strong>¿Los mods de <span class="important">Def</span>/<span class="important">Atk</span>/movimiento se usan una vez? Porque dicen "para la batalla" pero la habilidad <span class="important">Dash</span> dice "toda la batalla".</strong><br />
    Duran toda la batalla. Los dos términos son lo mismo.</p>

    <p><strong>¿Puedo usar <span class="important">Longsword</span> en cada ataque para infligir 1 daño extra? ¿Toda la batalla?</strong><br />
    Sí. Siempre que tengas Dex para ello.</p>

    <p><strong>¿Puedo usar <span class="important">Scrambler</span> para ambos intentos?</strong><br />
    Solo un intento. Es un consumible.</p>

    <p><strong>¿Cómo funciona <span class="important">Parry</span>?</strong><br />
    El Baddie atacante no se considera que haya infligido ningún daño a efectos de cualquier efecto que se active "al infligir daño".</p>

    <p><strong>¿Cómo funciona <span class="important">Lucky</span> con el daño extra?</strong><br />
    Esto se aplica incluso si no se inflige daño (aumentando el daño infligido a 1).</p>
                </>
            )}
            
            {gearloc === 'Ghillie' && (
                <>
                <h1>FAQ Ghillie</h1>
                <p><strong>¿Cómo funcionan las trampas?</strong><br />
    Para la trampa de Ghillie, solo la activarás después de que todos los <span class="important">Baddies</span> estén colocados. Está indicado en la hoja de referencia.</p>

    <p><strong>¿Se debe usar el valor exacto lanzado de <span class="important">Camo</span>, o puedo usar "hasta" el valor lanzado?</strong><br />
    ¡Solo el valor exacto!</p>

    <p><strong>¿Qué pasa con las mascotas de Ghillie si Ghillie es <span class="important">KO’d</span>?</strong><br />
    Las mascotas de Ghillie se retiran del tablero y se agotan inmediatamente.</p>
                </>
            )}
            {gearloc === 'Duster' && (
                <>
                <h1>FAQ Duster</h1>
                <p><strong>¿Entra Nightshade en el <span class="important">Battle Mat</span> al final de una ronda, si Duster ha sido <span class="important">KO’d</span>?</strong><br />
    No. Duster necesita estar en juego y tener menos del HP completo para que Nightshade se despliegue al final de una ronda.</p>

    <p><strong>¿Puede Duster lanzar <span class="important">Promise of Prey</span> y bloquear el dado, y luego Nightshade aplicar el dado a un <span class="important">Baddie</span>?</strong><br />
    Duster lanza y aplica el dado en su turno. Luego, en el turno de Nightshade, se usa el beneficio.</p>

    <p><strong>¿Dónde se posiciona Nightshade cuando entra en el <span class="important">Battle Mat</span>?</strong><br />
    Nightshade se considera una unidad de cuerpo a cuerpo y entrará en batalla en cualquier posición de inicio de cuerpo a cuerpo disponible para Gearloc.</p>

    <p><strong>¿Puede un Baddie con <span class="important">Dodge</span> aún ser envenenado por un ataque al que se le ha aplicado <span class="important">Blade Dip</span> de Duster?</strong><br />
    Sí. Blade Dip no requiere que el ataque haga daño real para aplicar el veneno.</p>

    <p><strong>¿Cómo funciona <span class="important">Promise of Prey</span>?</strong><br />
    Esto duplica el daño total de ataque infligido al Baddie, antes de que se apliquen la defensa y otros efectos mitigantes.</p>

    <p><strong>¿Cómo funciona <span class="important">Killer Instinct</span> con <span class="important">Lashback</span>?</strong><br />
    Este efecto solo se activa si Nightshade pierde HP por el daño infligido a él. Si algún efecto le da defensa u otra forma de mitigación de daño a Nightshade y, como resultado, no pierde HP por el daño entrante, Lashback no se activa.</p>

    <p><strong>¿Cómo funciona <span class="important">Killer Instinct</span> con <span class="important">Leech</span>?</strong><br />
    Este efecto se activa con el daño infligido por Nightshade. Así que esto cura a Nightshade incluso si este daño infligido es mitigado por el enemigo (por ejemplo, usando Hardy, Thick Skin, defensa activa, etc.).</p>
                </>
            )}

            {gearloc === 'Stanza' && (
                <>
                <h1>FAQ Stanza</h1>
                <p><strong>¿Cómo funciona <span class="important">Electrify</span> de Stanza?</strong><br />
    El daño del número blanco se aplica al objetivo de Stanza. El daño del número azul se aplica a una sola unidad adyacente al objetivo de Stanza. Si Stanza es la única unidad adyacente a su objetivo, ella recibirá este daño.</p>

    <p><strong>Los <span class="important">Baddies</span> a distancia no pueden moverse. ¿Pero puedo usar la canción <span class="important">Forever Mine</span> para controlar un Baddie a distancia y moverlo más cerca de mí?</strong><br />
    Sí.</p>

    <p><strong>¿Puede <span class="important">Forever Mine</span> controlar los tentáculos de <span class="important">Barnacle</span>?</strong><br />
    No. Los tentáculos no tienen valor de #Pt. Por lo tanto, no pueden ser afectados por ninguna habilidad que requiera un valor de #Pt.</p>

    <p><strong>¿Lanzar para enfocar cuesta <span class="important">Dex</span>?</strong><br />
    Sí. Cuando usas la hoja de Gaining Focus para ganar enfoque, cuesta 1 Dex por canción.</p>

    <p><strong>Si controlo un Baddie con 2 objetivos (debilidades) con <span class="important">Trance Stanza</span>, ¿necesito apuntar a los dos Baddies de debilidad?</strong><br />
    No.</p>

    <p><strong>La canción <span class="important">BQ Flush</span> elimina al Baddie más bajo en el mapa de batalla. Si lucho contra solo un Baddie de 5 puntos, ¿puedo elegir cuál?</strong><br />
    Sí. Pero vuelve completamente curado.</p>

    <p><strong>¿Puede Stanza usar <span class="important">Forever Mine</span> para controlar un Baddie que tiene las habilidades <span class="important">Blind Strike</span> y <span class="important">One Mind</span>? ¿No activando Blind Strike pero controlando a las otras dos Bestias?</strong><br />
    Puedes activar Blind Strike, pero no puedes controlar a las bestias con One Mind. Usas la habilidad (One Mind) como el Baddie, lo que hace que el otro Baddie tipo Bestia ataque a sus objetivos (Gearlocs). No tienes control sobre los otros Baddies.</p>
                </>
            )}

            {gearloc === 'Gasket' && (
                <>
                <h1>FAQ Gasket</h1>
                <p><strong>¿Funciona la habilidad <span class="important">Gut</span> de Gasket alrededor de <span class="important">Thick Skin</span> y <span class="important">Hardy</span>?</strong><br />
    Sí, pero Hardy aún recibe solo 1 de daño en su turno. La primera parte de la habilidad, "Inflige # de daño a una unidad adyacente con un dado de efecto de sangrado sobre ella", funciona como cualquier ataque o habilidad basada en daño estándar. Tanto Thick Skin como Hardy se considerarán al contabilizar este daño. La segunda parte de la habilidad, "La unidad recibe 1 de daño verdadero por cada posición que se mueva", usa específicamente daño verdadero, que funcionará alrededor de Thick Skin. Como Hardy menciona tener en cuenta el daño verdadero en su descripción, aún perderá solo 1 HP incluso si se mueve dos veces en su turno.</p>

    <p><strong>¿Cómo funciona exactamente el <span class="important">Green Bone</span> en la <span class="important">Hydro Valve</span> de Gasket?</strong><br />
    En cualquier momento durante la fase de Resolver tu Tirada en el turno de Gasket, debes colocar el Green Bone en el Plan de Respaldo. Si lanzaste múltiples Bones, puedes elegir en qué orden colocarlos. Luego, debes resolver la ranura del Plan de Respaldo en la que está el Green Bone, eliminándolo a él y a todos los Bones a la izquierda como lo harías normalmente, y deslizando hacia abajo cualquier Bone restante. Si el Green Bone es tu segundo Bone, gastarás los dos Bones pero no obtendrás ningún beneficio, ya que no hay habilidades asociadas con la segunda ranura de Bones. Si el Green Bone es tu sexto Bone, resolverás la habilidad de 6 Bones de ganar el Innate +1 de Gasket.</p>

    <p><strong>¿Se agotan las habilidades de Gasket si una habilidad de Baddie las fuerza a hacerlo? (por ejemplo, <span class="important">Shock</span>)</strong><br />
    Sí. Las habilidades de Gasket no se agotan después de ser usadas, sin embargo, un efecto que obligue a los Gearlocs a agotar dados de sus ranuras Activas, Bloqueadas o de Habilidad agotará las habilidades de Gasket.</p>
                </>
            )}         

            {gearloc === 'Tink' && (
                <>
                <h1>FAQ Tink</h1>
                <p><strong>¿Puede Tink tener más de un Bot en el <span class="important">Battle Mat</span> al mismo tiempo?</strong><br />
    No. Puedes estar construyendo un segundo Bot en tu Área de Preparación, pero solo un Bot puede estar en el Battle Mat a la vez.</p>

    <p><strong>¿Qué pasa con un Bot en el <span class="important">Battle Mat</span> si Tink es <span class="important">KO'd</span>?</strong><br />
    Se queda en el Battle Mat, pero no puede ser activado ni operado por ningún Gearloc aparte de Tink. Los Bots permanecerán en el tablero hasta que su HP se reduzca a cero, y luego serán agotados. Sus accesorios permanecerán en el Área de Preparación hasta que el Bot sea KO'd, momento en el cual serán devueltos al Área de Habilidades. Cualquier Bot en el Área de Preparación permanecerá en el Área de Preparación.</p>

    <p><strong>¿Necesita el Bot de Tink tener energía de batería restante para usar un Plan de Respaldo como <span class="important">Hot Gasket</span>?</strong><br />
    Sí. El Bot de Tink necesita haber gastado una energía de batería durante su turno para poder realizar cualquier tarea, incluyendo activar su Plan de Respaldo.</p>

    <p><strong>¿Pueden los Bots de Tink hacer un <span class="important">Auto-Attack</span> en el turno en que se despliegan?</strong><br />
    Sí. Si planeas desplegar el Bot de Tink más tarde en el turno, debes elegir un objetivo para el Bot al mismo tiempo que declaras el objetivo de Tink, aunque el Bot aún no esté en el Battle Mat. Después de desplegar tu Bot durante la fase de Resolver tu Tirada, puedes reducir la energía de la batería del Bot para realizar un Auto-Attack contra el objetivo previamente declarado.</p>
                </>
            )}                  
        </div>
    );
};

export default Gearloc;