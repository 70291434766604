import './Prologo.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TypingEffect from './TypingEffect'; // Importa el componente TypingEffect
import UsoLocal from './UsoLocal';
import Audio from './Audio';
import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Button from '@mui/material/Button';

const Epilogo = () => {
    const [epilogoDevuelto, setEpilogoDevuelto] = useState('');
    const location = useLocation(); // Hook para obtener el estado de la navegación
    const { deckInfoJSON, resultado } = location.state || {}; // Obtén la cadena JSON del estado
    const [zoomLevel, setZoomLevel] = useState(1);

    const deckInfo = deckInfoJSON ? JSON.parse(deckInfoJSON) : {}; // Parsear JSON si es una cadena

    const { selectedVillain } = deckInfo;

    const [epilogoDerrota,setEpilogoDerrota] = useState(true);
    const [imagenEpilogo,setImagenEpilogo] = useState('');

    const navigate = useNavigate();

    const ZoomIcon = document.getElementById('zoom-icon');

    const goToMain = () => {
        navigate('/');
    };

    useEffect(() => {
        if(resultado === 'victoria'){
            obtenerEpilogoVictoria(selectedVillain);
            setEpilogoDerrota(false);
        }
        if(resultado==='derrota'){
            obtenerEpilogoDerrota(selectedVillain.name);
        }

    }, [epilogoDevuelto]);



    const obtenerEpilogoDerrota = (villanoSeleccionado) => {
        switch (villanoSeleccionado) {
            case 'Drellen':
                setEpilogoDevuelto('  El eco de la risa de Drellen resuena en el bosque, mientras la oscuridad se cierne sobre Daelore. Los Gearlocs, derrotados y dispersos, son testigos de cómo el reino cae bajo el yugo de un tirano renacido. La esperanza, al igual que el sol, parece haberse ocultado, dejando a los sobrevivientes en una interminable noche.');
                break;
            case 'Drellen Resentido':
                setEpilogoDevuelto('  Con Drellen Resentido triunfante, la ira de su venganza se desata sobre Daelore. Los Gearlocs, derrotados, deben enfrentar un reino devastado por la furia de su enemigo. La desolación y el caos reinan, mientras el resentimiento de Drellen marca una era de destrucción y desesperanza.');                
                break;
            case 'Mulmesh':
                setEpilogoDevuelto('  Mulmesh, triunfante, desata su furia sobre Daelore. Los Gearlocs, vencidos, observan cómo el reino cae bajo el yugo de su poder. La devastación se extiende mientras el caos reina en la tierra, marcando una época de desesperanza bajo la tiranía de Mulmesh.');                
                break;
            case 'Mulmesh Enojado':
                setEpilogoDevuelto('  Mulmesh Enojado, victorioso, deja a Daelore devastado por su furia incontrolable. Los Gearlocs, derrotados, observan cómo el reino se convierte en un paisaje de caos y destrucción. La esperanza se disuelve bajo el peso de la rabia de Mulmesh, mientras el reino queda en ruinas.');                
                break;
            case 'Nom':
                setEpilogoDevuelto('  Las montañas se sacuden bajo la pisada de Nom, quien, habiendo derrotado a los Gearlocs, deja un rastro de destrucción congelada a su paso. Daelore, ahora sumido en un invierno eterno, se convierte en una tierra desolada, donde la vida lucha por sobrevivir. La derrota de los Gearlocs marca el inicio de una era de hielo y desesperación.');                
                break;
            case 'Nom Hambrón':
                setEpilogoDevuelto('  Nom Hambrón, triunfante, deja a Daelore consumido por su apetito voraz. Los Gearlocs, derrotados, deben enfrentar un reino en ruinas bajo el dominio del villano hambriento. La esperanza se desvanece mientras la tierra queda destruida y consumida por la furia de Nom.');                
                break;
            case 'Gendricks':
                setEpilogoDevuelto('  Los estandartes de Gendricks ondean sobre las tierras conquistadas, marcando el fin de la resistencia Gearloc. Daelore cae bajo el yugo del tirano, y el futuro parece sombrío. El eco de los gritos de guerra de Gendricks resuena en cada rincón, mientras la esperanza se desvanece entre las sombras.');                
                break;
            case 'Gendricks que echa humo':
                setEpilogoDevuelto('  Gendricks que Echa Humo se alza como el vencedor, y el reino queda sumido en una nube de humo tóxico. Los Gearlocs, vencidos, se ven obligados a refugiarse mientras el humo corrosivo envuelve Daelore. La esperanza se disuelve en la niebla venenosa, y la tierra se convierte en un lugar inhóspito.');                
                break;
            case 'Rey Goblin':
                setEpilogoDevuelto('  El Rey Goblin se alza victorioso, y su reino de caos se extiende por Daelore como una plaga. Los Gearlocs, derrotados, son obligados a huir, mientras los goblins se apoderan de la tierra. La esperanza se desvanece mientras el caos y la anarquía reinan supremos bajo la mano férrea del Rey Goblin.');                
                break;
            case 'Rey Goblin Encolerizado':
                setEpilogoDevuelto('  El Rey Goblin Encolerizado triunfa, y su furia desata una ola de destrucción sobre Daelore. Los Gearlocs, derrotados, ven cómo el reino queda sometido a la rabia y el caos. La esperanza se desmorona bajo el dominio implacable del rey goblin.');                
                break;
            case 'Marrow':
                setEpilogoDevuelto('  Con los Gearlocs derrotados, Marrow se convierte en el señor absoluto de Daelore, un reino donde la vida se extingue lentamente. La muerte camina libremente por las tierras, y la oscuridad cubre el sol. Daelore se convierte en un mundo sombrío y sin esperanza, bajo el dominio eterno de Marrow.');                
                break;
            case 'Marrow Resentido':
                setEpilogoDevuelto('  Marrow Resentido, triunfante, deja a Daelore marcado por su venganza. Los Gearlocs, vencidos, enfrentan un reino sumido en la desolación y la furia del villano. La esperanza se desmorona mientras el resentimiento de Marrow arrasa con la tierra.');                
                break;
            case 'Duster':
                setEpilogoDevuelto('  Con la victoria de Duster, Daelore se encuentra dividido y roto. La traición ha triunfado, y los Gearlocs, una vez unidos, están ahora dispersos y derrotados. El reino que una vez conocieron se desvanece bajo la oscuridad, mientras Duster, en su amargura, se erige como la sombra que consume todo.');                
                break;
            case 'Duster Indignada':
                setEpilogoDevuelto('  Con Duster Indignada triunfante, Daelore queda arrasado por la furia de la villana. Los Gearlocs, vencidos, ven cómo el reino se convierte en un campo de ruinas bajo la influencia de la rabia de Duster. La esperanza se disuelve en la devastación y el caos de la indignación.');                
                break;
            case 'Barnacle':
                setEpilogoDevuelto('  Barnacle y su tripulación dominan las aguas, y Daelore se convierte en un reino asediado por el terror pirata. Los Gearlocs, derrotados, se retiran al interior, dejando las costas y el mar bajo el control del despiadado capitán. La libertad del reino se desvanece con cada ola que choca contra las costas conquistadas.');                
                break;
            case 'Barnacle Iracundo':
                setEpilogoDevuelto('  Barnacle y su tripulación dominan las aguas, y Daelore se convierte en un reino asediado por el terror pirata. Los Gearlocs, derrotados, se retiran al interior, dejando las costas y el mar bajo el control del despiadado capitán. La libertad del reino se desvanece con cada ola que choca contra las costas conquistadas.');                
                break;
            case 'Kollossum':
                setEpilogoDevuelto('  Con Kollossum victorioso, Daelore se tambalea bajo el peso de su destrucción. Los Gearlocs, derrotados, ven cómo el coloso arrasa con todo a su paso, dejando un paisaje de ruinas. La tierra, antes fértil, queda marcada por las huellas de la bestia, mientras la esperanza se derrumba bajo la sombra de Kollossum.');                
                break;
            case 'Kollossum Furioso':
                setEpilogoDevuelto('  Con Kollossum Furioso victorioso, Daelore queda arrasado por la rabia desatada del coloso. Los Gearlocs, derrotados, ven cómo el reino se convierte en un campo de destrucción, donde la esperanza parece ser solo una ilusión perdida en el caos de la furia implacable.');                
                break;
            case 'Reina Goblin':
                setEpilogoDevuelto('  La risa de la Reina Goblin resuena mientras sus enemigos caen uno a uno. Con los Gearlocs derrotados, su ejército marcha sobre Daelore, sumiendo al reino en una oscuridad sin fin. Los pueblos arden, y el caos reina supremo, mientras la esperanza se desvanece bajo la sombra de la reina victoriosa.');                
                break;
            case 'Reina Goblin Encolerizada':
                setEpilogoDevuelto('  La Reina Goblin Encolerizada prevalece, y su furia se desata sobre Daelore con renovada intensidad. Los Gearlocs, derrotados, observan cómo el reino queda sometido bajo la rabia de la reina y su ejército goblin. La desesperanza se apodera de la tierra, y el caos reina en el reino.');                
                break;
            case 'Nobulous':
                setEpilogoDevuelto('  Con Nobulous victorioso, la oscuridad se cierne sobre Daelore como una manta opresiva. Los Gearlocs, derrotados, ven cómo el reino cae bajo el control del señor de las sombras. La desesperación y el miedo reinan en cada rincón, mientras el poder oscuro se expande sin límite.');                
                break;
            case 'Nobulous Cascarrabias':
                setEpilogoDevuelto('  Nobulous Cascarrabias se alza como el vencedor, dejando a Daelore sumido en una oscuridad de ira y desesperación. Los Gearlocs, vencidos, ven cómo el reino se convierte en un lugar de caos y desesperanza. La esperanza se disuelve en la furia del villano, mientras el reino queda marcado por la ira.');                
                break;
            case 'Vol\'kesh':
                setEpilogoDevuelto('  Vol\'kesh se alza triunfante, y las tormentas que desató arrasan con Daelore. Los Gearlocs, vencidos, se ven obligados a refugiarse mientras el caos elemental se desata. El reino se convierte en un paisaje devastado, donde la furia de Vol\'kesh transforma la tierra en un caos de tormentas y ruinas.');                
                break;
            case 'Vol\'Kesh Irritada':
                setEpilogoDevuelto('  Vol\'Kesh Irritada se alza victoriosa, dejando a Daelore en ruinas bajo su furia. Los Gearlocs, derrotados, observan cómo el reino queda envuelto en el caos y la destrucción. La esperanza se desvanece mientras la tierra se convierte en un campo de devastación bajo el poder implacable de Vol\'Kesh.');                
                break;
            case 'Autómata':
                setEpilogoDevuelto('  Autómata se alza como el victorioso comandante de un ejército mecánico que ha arrasado con Daelore. Los Gearlocs, derrotados, observan cómo la tierra queda cubierta por la fría y despiadada maquinaria. El reino queda bajo el control de un imperio de acero y engranajes, donde la esperanza parece ser una máquina oxidada.');                
                break;
            case 'Rok & Rol':
                setEpilogoDevuelto('  Rok & Rol se alzan como los vencedores, y su combinación de caos y destrucción deja a Daelore en ruinas. Los Gearlocs, derrotados, se ven obligados a abandonar el reino mientras la devastación se extiende bajo el dominio implacable de la dupla. La tierra queda marcada por su paso, con la esperanza casi extinguida.');                
                break;
            case 'Cinder':
                setEpilogoDevuelto('  Cinder, triunfante, deja a Daelore en ruinas ardientes. Los Gearlocs, derrotados, observan cómo el reino se consume en llamas incontrolables. La tierra arde bajo el furor del incendiario, y la esperanza parece ser solo un sueño distante en medio de la devastación.');                
                break;
            case 'Domina':
                setEpilogoDevuelto('  Con Domina victoriosa, Daelore queda bajo un yugo de control mental implacable. Los Gearlocs, derrotados, se encuentran sometidos a la voluntad de la maestra, mientras el reino es manipulado a su antojo. La libertad se convierte en un recuerdo lejano, y la esperanza se disuelve en la sumisión.');                
                break;
            case 'Gavenkog':
                setEpilogoDevuelto('  Gavenkog, victorioso, deja a Daelore sumido en una marea de veneno y desolación. Los Gearlocs, vencidos, ven cómo el reino se convierte en un lugar tóxico e inhabitable. La esperanza se desmorona bajo la sombra de la plaga, mientras el reino se desintegra lentamente bajo la influencia del señor de los venenos.');                
                break;
            case 'Nexus':
                setEpilogoDevuelto('  Nexus, triunfante, deja a Daelore en ruinas arcanas, donde la magia descontrolada arrasa con todo a su paso. Los Gearlocs, derrotados, se ven forzados a retirarse mientras el reino se convierte en un caos de energía desbordante. La esperanza parece ser una chispa perdida en la tormenta de destrucción mágica.');                
                break;
            case 'Leech':
                setEpilogoDevuelto('  Leech se alza victorioso, y Daelore queda vacío de su vitalidad, drenado hasta su esencia. Los Gearlocs, derrotados, ven cómo la tierra se convierte en un lugar estéril y muerto, donde la esperanza se ha desvanecido junto con la vida que una vez prosperó.');                
                break;
            case 'Amanight':
                setEpilogoDevuelto('  Amanight, triunfante, sumerge a Daelore en una oscuridad eterna donde la luz nunca vuelve a brillar. Los Gearlocs, vencidos, observan cómo el reino se convierte en un lugar sin esperanza, envuelto en la noche sin fin bajo el control del maestro de la oscuridad.');                
                break;
            case 'Oxide':
                setEpilogoDevuelto('  Oxide, victorioso, deja a Daelore en un estado de desintegración corrosiva. Los Gearlocs, derrotados, se ven obligados a abandonar un reino que se desintegra bajo la influencia corrosiva. La esperanza se disuelve en el aire mientras el reino se convierte en un campo de ruinas desmoronadas.');                
                break;
            case 'Bat':
                setEpilogoDevuelto('  Con la derrota frente a Bat, Daelore se encuentra en una crisis. Los Gearlocs, vencidos, deben enfrentar la continua amenaza que Bat representa. El reino se tambalea bajo el peso de una amenaza que varía en dificultad, mientras la desesperanza se apodera de las tierras.');                
                break;
            case 'Blobulous':
                setEpilogoDevuelto('  Blobulous se alza victorioso, y Daelore queda sumido en una pesadilla viscosa. Los Gearlocs, derrotados, observan cómo el reino se convierte en un paisaje de corrupción y desintegración. La esperanza se disuelve en la masa de slime, mientras la tierra queda irreconocible y dominada por la amenaza amorfa.');                
                break;
            case 'Locgear':
                setEpilogoDevuelto('  Locgear, triunfante, establece su dominio sobre Daelore con su ejército de autómatas. Los Gearlocs, derrotados, observan cómo el reino queda bajo un régimen de acero y engranajes. La esperanza parece ser un recuerdo distante mientras el dominio mecánico transforma el paisaje en un bastión de control implacable.');                
                break;
            default:
                setEpilogoDevuelto('  Error en el prólogo');
                break;
        }
    };

    const obtenerEpilogoVictoriaRage = (villanoSeleccionado) => {
        switch (villanoSeleccionado) {
            case 'Drellen Resentido':
                setEpilogoDevuelto('La derrota de Drellen Resentido trae una sensación de justicia y alivio a Daelore. Su furia se apaga, y el reino puede comenzar a sanar de su venganza. Los Gearlocs han logrado restaurar la paz, y el resentimiento de Drellen se convierte en una lección sobre la importancia de enfrentar la adversidad con coraje.');
                break;
            case 'Mulmesh Enojado':
                setEpilogoDevuelto('Con la derrota de Mulmesh Enojado, Daelore se alivia de la amenaza desatada por el villano. Los Gearlocs han logrado restaurar la calma en el reino después de la tormenta de ira. La tierra comienza a recuperarse y la esperanza renace tras la furia de Mulmesh.');
                break;
            case 'Nom Hambrón':
                setEpilogoDevuelto('Con la derrota de Nom Hambrón, Daelore se libera de la amenaza de hambre voraz. Los Gearlocs han salvado el reino de la destrucción inminente, y la paz regresa mientras la tierra comienza a sanar. La esperanza se renueva tras la victoria sobre la amenaza hambrienta.');
                break;
            case 'Gendricks que echa humo':
                setEpilogoDevuelto('Con la caída de Gendricks que Echa Humo, el humo tóxico se disipa y Daelore recupera su claridad. Los Gearlocs han enfrentado con éxito la amenaza venenosa, y el reino puede respirar de nuevo. La paz se restablece, y el aire vuelve a ser puro y libre de las toxinas de Gendricks.');                
                break;
            case 'Rey Goblin Encolerizado':
                setEpilogoDevuelto('Con la caída del Rey Goblin Encolerizado, Daelore recupera la paz que había perdido. Los Gearlocs han superado la amenaza del tirano goblin, y el reino puede comenzar a sanar. La victoria es un testimonio del valor y la fuerza de los héroes en tiempos de adversidad.');                
                break;
            case 'Marrow Resentido':
                setEpilogoDevuelto('La caída de Marrow Resentido trae alivio a Daelore. Los Gearlocs han superado al villano consumido por la venganza, y el reino comienza a reconstruirse. La paz se restablece, y el resentimiento de Marrow se convierte en una lección sobre el poder del coraje y la unidad.');                
                break;
            case 'Duster Indignada':
                setEpilogoDevuelto('La derrota de Duster Indignada permite que Daelore recupere su paz después de la furia destructiva de la villana. Los Gearlocs han demostrado su valentía al superar esta amenaza encolerizada, y el reino comienza a sanar de la devastación provocada por Duster.');                
                break;
            case 'Barnacle Iracundo':
                setEpilogoDevuelto('La caída de Barnacle Iracundo trae un alivio crucial a Daelore. Los Gearlocs han superado la amenaza del villano furioso, y el reino comienza a recuperar su equilibrio. La paz se restablece, y el caos provocado por Barnacle se convierte en un recuerdo de la valentía de los héroes.');                
                break;
            case 'Kollossum Furioso':
                setEpilogoDevuelto('La derrota de Kollossum Furioso marca una victoria significativa para Daelore. El reino, aliviado, puede reconstruirse tras la furia devastadora del coloso. Los Gearlocs han demostrado su valentía y capacidad para superar la amenaza extrema, restaurando la paz y la estabilidad en la tierra.');                
                break;
            case 'Reina Goblin Encolerizada':
                setEpilogoDevuelto('Con la caída de la Reina Goblin Encolerizada, el reino recupera su estabilidad tras la furia goblin. Los Gearlocs han vencido a la reina furiosa, y Daelore comienza a reconstruirse después de la tormenta. La paz se restablece, y la amenaza goblin se convierte en un recuerdo distante.');                
                break;
            case 'Nobulous Cascarrabias':
                setEpilogoDevuelto('La caída de Nobulous Cascarrabias trae un respiro de alivio a Daelore, liberando al reino de la ira oscura que había consumido. Los Gearlocs han superado la amenaza con valentía, y la tierra comienza a sanar mientras la esperanza se renueva tras la derrota del villano.');                
                break;
            case 'VolKesh Irritada':
                setEpilogoDevuelto('La derrota de Vol\'Kesh Irritada permite que Daelore recupere su tranquilidad tras la tormenta de furia. Los Gearlocs han logrado restaurar la paz y la estabilidad, y la tierra comienza a sanar. La victoria es un reflejo del coraje y la determinación frente a la adversidad.');                
                break;
            default:
                setEpilogoDevuelto('Error en el epílogo');
                break;
        }
    };

    let typingEffectRef = null;

    const handleImageClick = () => {
        if (typingEffectRef) {
            typingEffectRef.handleClick();
        }
    };

    const getEpilogoAudio = (imagenEpilogo) => {
        if (!imagenEpilogo) return null;
    
        const audioSrc = imagenEpilogo.split('.jpg')[0].replace('/website/', '/website/audio/') + '.mp3';

        return audioSrc;
    }

    const getEpilogoAudioTexto = () => {
        
        const audioSrc = '/static/audio/epilogos/derrota/epilogo_derrota_'+selectedVillain.keyname + '.mp3';
        return audioSrc;
    }

    const obtenerUrlVictoria = (villainSelected) => {
        return UsoLocal('epilogos%2Fepilogo_'+villainSelected.clave+'_'+villainSelected.keyname+'.jpg?alt=media');
    };

    const obtenerEpilogoVictoria = (selectedVillain) => {
        if(selectedVillain.clave !== 'rot'){
            const urlVictoria = obtenerUrlVictoria(selectedVillain);
            setImagenEpilogo(urlVictoria);
        }else{
            setEpilogoDerrota(true); //Como no existe la imagen, hay que crearla con el marco y usamos el mismo que el de la derrota

        }

    };

    
    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel === 1 ? 1.2 : 1;
            ZoomIcon.style.color = newZoomLevel === 1 ? '#353535' : '#C21807';
            return newZoomLevel;
        });
    };

    

    return (
        <div className="">
            {epilogoDerrota && (
                <div className="central-image" style={{ position: 'relative' }} onClick={handleImageClick}>
                <img
                    src={UsoLocal('epilogos%2Fepilogo_vacio.jpg?alt=media')}
                    alt={'epílogo de la historia'}
                    className="villain-image"
                    style={{ marginTop: '10px', width: '100%', height: 'auto', transform: `scale(${zoomLevel})` }} // Ajusta el estilo según sea necesario
                />

                <div class="tools">
                        <Fab className="tool" color="default" aria-label="zoom-in" disabled>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getEpilogoAudioTexto()} />

                    </div>


                <div className='titulo-prologo'>
                    <h2>Epílogo</h2>
                </div>
                <TypingEffect
                    text={epilogoDevuelto}
                    speed={40}
                    ref={(ref) => typingEffectRef = ref} // Pasa la referencia
                />
                </div>
            )}

            {!epilogoDerrota && (
                <div className="central-image" style={{ position: 'relative' }}>
                <img
                    src={imagenEpilogo}
                    alt={'epílogo de la historia'}
                    className="villain-image"
                    style={{ marginTop: '10px', width: '100%', height: 'auto', transform: `scale(${zoomLevel})` }} // Ajusta el estilo según sea necesario
                />
                <div class="tools">
                        <Fab className="tool" color="default" aria-label="zoom-in" disabled>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getEpilogoAudio(imagenEpilogo)} />

                    </div>

                </div>
            )}

            
            <div className="seccion-boton">
                <Button variant="contained" onClick={goToMain} style={{minHeight: '50px'}}>Ir a inicio </Button>
            </div>
        </div>
    );
};

export default Epilogo;

