import './Novedades.css';
import React, { useState } from 'react'; // Importar useState
import { useNavigate } from 'react-router-dom';
import { useStateContext } from './StateContext'; // Importa el hook del contexto
import { useLocation } from 'react-router-dom'; // Hook para obtener el estado de la navegación
import { Button } from '@mui/material';

const Novedades = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Hook para obtener el estado de la navegación
    const { deckInfoContext } = location.state || {}; // Obtén la cadena JSON del estado

    const deckInfoEncuentro = deckInfoContext ? JSON.parse(deckInfoContext) : {}; // Parsear JSON si es una cadena

    const { updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress, setDeckInfoContext } = useStateContext(); // Usa el contexto para actualizar el estado

    const goToMain = () => {
        navigate('/');
    };

    const [deckInfoJSON, setDeckInfoJSON] = useState(deckInfoContext);

    const volverAventura = () => {
        //Esta función tiene que coger el deckInfo y hacer una función navigate a aventura. Colocar con CSS el botón arriba a la izquierda y añadir una flecha (visual)
        console.log(deckInfoJSON);
        navigate('/Aventura', { state: { deckInfoJSON } });
      };

    return (
        <div className="faq-container">
   {/* Conditionally render the button if deckInfoJSON is not null or undefined */}
   {deckInfoJSON && (
            <Button variant="contained" onClick={volverAventura} style={{minHeight: '50px'}}>Volver a la aventura</Button>

            )}
            {/* Other content */}
            <h2>Novedades</h2>
            <section className="faq-section">
                <h4>0.1.7 - 29 Noviembre 2024</h4>
                <h3>Mejorada la vista de combate tirano</h3>
                <p>
                    He añadido a la vista de combate de villano el progreso, días y BQ en la parte superior y FAQ según el villano de la aventura.
                </p>
                <h2>Combate Tirano</h2>
                <p>
                    <img alt='Últimas aventuras' src='/static/resources/novedades/019_1.png' />
                </p>
            </section>
            <section className="faq-section">
                <h4>0.1.7 - 28 Noviembre 2024</h4>
                <h3>La IA llega a Too Many Bones</h3>
                <p>
                    Se ha añadido un asistente de inteligencia artificial que estará disponible para tí durante tus partidas a Too Many Bones. Tienes el icono en la cabecera. Esta primera versión es un beta y puede que las respuestas no sean correctas. Seguiré entrenando el modelo para llegar a algo útil para los jugadores.
                </p>
                <h2>Cabecera - Asistente IA</h2>
                <p>
                    <img alt='Últimas aventuras' src='/static/resources/novedades/018_1.png' />
                </p>
            </section>
            <section className="faq-section">
                <h4>0.1.7 - 24 Noviembre 2024</h4>
                <h3>FAQ de los Gearlocs</h3>
                <p>
                    En ocasiones durante la partida surgen dudas acerca del funcionamiento de las habilidades de los Gearlocs. Acabo de añadir una funcionalidad que muestra las preguntas más frecuentes de los Gearlocs. La funcionalidad se encuentra bajo del avatar de los Gearlocs durante la Aventura.

                </p>
                <h2>Aventura - Puntos de entrenamiento - FAQ</h2>
                <p>
                    <img alt='Últimas aventuras' src='/static/resources/novedades/017_1.png' />
                </p>
                <p>
                    <img alt='Últimas aventuras' src='/static/resources/novedades/017_2.png' />
                </p>
            </section>
            <section className="faq-section">
                <h4>0.1.6 - 24 Noviembre 2024</h4>
                <h3>Ajustes en Encuentro especial y funciones de guardado</h3>
                <p>
                    Se ha añadido la función de audio, flip y zoom de carta en la página de Añadir Encuentro Especial.
                </p>
                <p>
                    Se ha corregido el fallo de guardado que no tenía en cuenta los entrenamientos de los gearlocs.
                </p>
                <p>
                    Se ha corregido el fallo de color de botón audio que permanecía en verde al cambiar de página.
                </p>
                <p>
                    Por último se ha hecho bastante trabajo que no se ve como refactorizaciones de Aventura, gestión de versiones GitHub y otras mejoras en el servidor para garantizar el correcto funcionamiento de la App.
                </p>
            </section>
            <section className="faq-section">
                <h4>0.1.5 - 21 Noviembre 2024</h4>
                <h3>Las últimas aventuras</h3>
                <p>
                    Ya puedes saber lo que otros jugadores están jugando, con qué gearlocs, contra que villano y cuando empezó la partida. Puedes encontrar esta información en la pagina de incio abajo del todo antes de empezar tu aventura, para que te sirva de inspiración al elegir tus gearlocs y villanos!
                </p>
                <h2>Foorter - Inicio</h2>
                <p>
                    <img alt='Últimas aventuras' src='/static/resources/novedades/015_1.png' />
                </p>
            </section>
            <section className="faq-section">
                <h4>0.1.4 - 21 Noviembre 2024</h4>
                <h3>Libro de batalla</h3>
                <p>
                    El Libro de batalla contra los Malotes y Tiranos ya ha llegado a Daelore! Ahora puedes añadir antes de que empiece la encarnizada batalla las habilidades que tus oponentes tendrán, para tenerlos en la vista de Aventura y poder echar un vistazo en un segundo sin tener que tirar de manual ni buscar la habilidad cada vez que se te olvida. Además, como todo libro que se aprecie, el libro de batalla se puede cerrar para que no moleste ni te quite el foco de lo que realmente importa. Para añadir reglas al Libro de batalla he añadido un botón en el menú de Lectura de reglas. Puedes ver las imágenes a continuación para hacerte una idea.
                </p>
                <h2>Lectura de reglas - Aventura</h2>
                <p>
                    <img alt='Reglas enriquecidas' src='/static/resources/novedades/014_1.png' />
                </p>
                <h2>Libro de batalla - Aventura</h2>
                <p>
                    <img alt='Reglas enriquecidas' src='/static/resources/novedades/014_2.png' />
                </p>
            </section>

            <section className="faq-section">
                <h4>0.1.3 - 21 Noviembre 2024</h4>
                <h3>Reglas enriquecidas</h3>
                <p>
                    Se ha añadido la posibilidad de consultar las habilidades de los Malotes de Too Many Bones en la pantalla de aventura. Ahora puedes consultar las habilidades de los Malotes en cualquier momento de la partida. Estos textos continen ahora emojis cuando se referencian conceptos claves para una mejor legibilidad e imagenes sobre los dados a utilizar.
                </p>
                <h2>Reglas - Aventura</h2>
                <p>
                    <img alt='Reglas enriquecidas' src='/static/resources/novedades/013_1.png' />
                </p>
            </section>

            <section className="faq-section">
                <h4>0.1.2 - 20 Noviembre 2024</h4>
                <h3>Lista de reproduccion en Spotify</h3>
                <p>
                    Nueva opción de escuchar la banda sonora no oficial de Too Many Bones en Spotify. Ahora puedes disfrutar de la música de Too Many Bones mientras juegas a la aplicación. Esta opción esta siempre visible en la cabecera haciendo click en el icono de la música. 
                </p>
                <p>
                    Agradecimientos: @Raulgonzo76.
                </p>
                <h2>Cabecera</h2>
                <p>
                    <img alt='Selección de Gearlocs' src='/static/resources/novedades/012_1.png' />
                </p>
            </section>

            <section className="faq-section">
                <h4>0.1.1 - 19 Noviembre 2024</h4>
                <h3>Selección de Gearlocs y puntos de entrenamiento</h3>
                <p>
                    En el dia de hoy introducimos la mejora de selección de Gearlocs cuando seleccionas personajes para tu aventura. Ahora puedes decidir exactamente quién de estos intrépidos aventureros serán los elegidos para tu aventura.
                    Además, en la pantalla de aventura tendrás un contador de entrenamiento por cada uno de los gearlocs que hayas seleccionado, para que puedas llevar un control de cuánto entrenamiento has acumulado.
                </p>
                <p>
                    Agradecimientos: @Terham123 y @lander_jrs
                </p>
                <h2>Pantalla de Inicio</h2>
                <p>
                    <img alt='Selección de Gearlocs' src='/static/resources/novedades/010_1.png' />
                </p>
                <h2>Pantalla de Aventura</h2>
                <p>
                    <img alt='Selección de Gearlocs' src='/static/resources/novedades/010_2.png' />
                </p>
            </section>

            <section className="faq-section">
                <h4>0.1.0 - 19 Noviembre 2024</h4>
                <h3>Cartas con más opciones</h3>
                <p>
                   He añadido más opciones a las cartas de aventura. Ahora puedes seleccionar entre varias opciones para cada carta de aventura.
                </p>
                <p><b>Lectura de reglas:</b> Ahora puedes consultar términos de Too Many Bones durante tus batallas, para ello solo tienes que buscar la palabra clave en español o inglés y de un vistazo podrás entender lo que significa. Se acabó el tener que estar con el manual del Malote en la mano.</p>
                <p><b>Voltear carta:</b> Aunque esta opción ya existía, he querido añadir otro lugar para accionarla. Esto dará la vuelta a la carta para que puedas leer su reverso.</p>
                <p><b>Lupa:</b> Los Gearlos, al igual que los humanos, con la edad nos cuesta más leer el diminuto texto de las cartas. Aquí tienes una pequeña ayuda para ampliar la carta para una mejor lectura.</p>
                <p><b>Lectura de la carta:</b> Esta ha sido sin duda la novedad estrella. Se ha ejecutado OCR y Text to Speech a más de 500 cartas para que no tengas que leer más.</p>

                <h2>Cartas de Aventura</h2>
                <p>
                    <img alt='Selección de Gearlocs' src='/static/resources/novedades/010_3.png' />
                </p>
            </section>

            <section className="faq-section">
                <h4>0.0.1 - 18 Noviembre 2024</h4>
                <h3>Rediseño de interfaz</h3>
                <p>
                    Se ha rediseñado la interfaz completamente de la app. La anterior aún se puede acceder desde <a href='https://tmbcards-3ec94.web.app/'>https://tmbcards-3ec94.web.app/</a>. Este trabajo es únicamente visual por lo que se añadirán pronto más funcionalidades. Agradecer a Miguel su predisposición y ayuda para hacer esta web posible.
                </p>
                <p>
                    Agradecimientos: @miguelmsa1.
                </p>
                <h2>Interfaz anterior y actual</h2>
                <div className="image-row" style={{maxWidth: '80%'}}>
                    <img alt='Interfaz anterior' style={{ width: '200px' }} src='/static/resources/novedades/001_1.png' />
                    <img alt='Interfaz actual' style={{ width: '200px' }} src='/static/resources/novedades/001_2.png' />
                </div>
            </section>

            <div className="seccion-boton">
                <button className='boton-aventura' onClick={goToMain}>Ir a inicio →</button>
            </div>
        </div>
    );
};

export default Novedades;


