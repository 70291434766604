import React, { useEffect, useState, useRef } from 'react';
import './VillainSelector.css';
import Audio from './Audio';
import UsoLocal from './UsoLocal';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import OpenAIComponent from './OpenAIComponent';

const VillainSelector = ({ villainCategories, onSelect, selectedVillain }) => {

    const [zoomLevel, setZoomLevel] = useState(1);


const villainListContainerRef = useRef(null);

const handleMouseDown = (e) => {
    const container = villainListContainerRef.current;
    container.isDown = true;
    container.startX = e.pageX - container.offsetLeft;
    container.scrollLeft = container.scrollLeft;
};

const handleMouseLeave = () => {
    const container = villainListContainerRef.current;
    container.isDown = false;
};

const handleMouseUp = () => {
    const container = villainListContainerRef.current;
    container.isDown = false;
};

const handleMouseMove = (e) => {
    const container = villainListContainerRef.current;
    if (!container.isDown) return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - container.startX) * 0.1; // Ajusta la velocidad del desplazamiento
    container.scrollLeft = container.scrollLeft - walk;
};
    
    // Estado para controlar si se muestra la imagen frontal o la trasera
    const [showBackImage, setShowBackImage] = useState(false);
    const [imageNumber, setImageNumber] = useState(0); // Estado para controlar el número de la imagen actual
    const flipIcon = document.getElementById('flip-icon');
    const ZoomIcon = document.getElementById('zoom-icon');

    const handleImageClick = () => {
        // Alterna entre la imagen frontal y la trasera
        flipIcon.style.color = showBackImage ? '#353535' : '#C21807';
        setShowBackImage(!showBackImage);
    };

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel === 1 ? 1.2 : 1;
            ZoomIcon.style.color = newZoomLevel === 1 ? '#353535' : '#C21807';
            return newZoomLevel;
        });
    };
    
    const getUrlImageTirano = (imgNumber) => {
        if (!selectedVillainObject) return null;

        return UsoLocal('tiranos%2Ftirano_'+selectedVillainObject.clave+'_'+selectedVillainObject.keyname+'_'+imgNumber)
    }

    const getVillainAudio = (imgNumber) => {
        if (!selectedVillainObject) return null;

        return UsoLocal('audio/tiranos%2Ftirano_'+selectedVillainObject.clave+'_'+selectedVillainObject.keyname+'_'+imgNumber+'_frontal.mp3');
    }

    const getVillainImage = (imgNumber) => {
        if (!selectedVillainObject) return null;

        // Usa la imagen trasera si showBackImage es verdadero
        return !showBackImage ? getUrlImageTirano(imgNumber)+'_frontal.jpg?alt=media': getUrlImageTirano(imageNumber)+'_trasera.jpg?alt=media';
        console.log(imageNumber);
    };

    // Encontrar el objeto del villano seleccionado
    const selectedVillainObject = villainCategories
        .flatMap(category => category.villains)
        .find(villain => villain.name === selectedVillain);

    // Verificar si la propiedad cartaExtra es igual a 1
    const showVillainControls = selectedVillainObject && selectedVillainObject.cartaExtra > 0;

    const handlePreviousCard = () => {
        setImageNumber((prevNumber) => (prevNumber > 0 ? prevNumber - 1 : prevNumber)); //Decrementa hasta 0
    };
    
    const handleNextCard = () => {
        setImageNumber((prevNumber) => (prevNumber < selectedVillainObject.cartaExtra ? prevNumber + 1 : prevNumber)); //Incrementa hasta 1
    };

    return (

        <div className="villain-selector-container">
            <h3>Selecciona un villano</h3>
            <div class="villain-categories-list">
                {villainCategories.map((category) => {
                        // Verifica si la categoría actual contiene el villano seleccionado
                        const isSelectedCategory = category.villains.some(villain => villain.name === selectedVillain);

                        return (
                            <Chip
                            key={category.category}
                            onClick={() => onSelect(category.villains[0].name)}
                            className={`category-button ${isSelectedCategory ? 'selected' : ''}`}
                            label={category.category}
                            avatar={<Avatar src={`/static/resources/avatar/${category.category}.jpg`} />}
                        />
                        );
                    })}
            </div>

            <div className="villain-list-container"
                            ref={villainListContainerRef}
                            onMouseDown={handleMouseDown}
                            onMouseLeave={handleMouseLeave}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}>
                {villainCategories.find(cat => cat.villains.some(villain => villain.name === selectedVillain)) && (
                    <div>
                        {villainCategories.find(cat => cat.villains.some(villain => villain.name === selectedVillain)).villains.map((villain) => (
                        
                            <Chip
                                key={villain.name}
                                onClick={() => onSelect(villain.name)}
                                className={`villain-button ${selectedVillain === villain.name ? 'selected' : ''}`}
                                label={villain.name}
                            />

                        ))}
                    </div>
                )}
            </div>

            <div className="villain-image-container">
                {selectedVillain && (
                    <>
                        <img
                            src={getVillainImage(imageNumber)}
                            alt={selectedVillain}
                            className="villain-image"
                            style={{ transform: `scale(${zoomLevel})` }}
                            onClick={handleImageClick}
                        />

                        <div class="tools">
                        <Fab className="tool" color="default" aria-label="remove" onClick={handleImageClick}>
                                <FlipCameraAndroidIcon id="flip-icon"/>
                        </Fab>
                        <Fab className="tool" color="default" aria-label="zoom-in" onClick={handleZoomIn}>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getVillainAudio(imageNumber)}/>

                        </div>
                    </>
                )}
            </div>

            {showVillainControls && (
                <div className="villain-controls-container">
                    <div className="villain-controls">
                        <button onClick={handlePreviousCard} className="day-button"> &lt; </button> 
                        <button onClick={handleNextCard} className="day-button"> &gt; </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VillainSelector;
