import './RevisarProximosEncuentros.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UsoLocal from './UsoLocal';

const RevisarProximosEncuentros = () => {
    const navigate = useNavigate();
    const [urls, setUrls] = useState([]);
    const [imageStates, setImageStates] = useState([false, false]); // false = frontal, true = trasera
    const [selectedImage, setSelectedImage] = useState(null); // Para mostrar la imagen en grande
    const [urlsOrder, setUrlsOrder] = useState([0, 1]); // Nuevo estado para almacenar el orden actual
    const location = useLocation();
    const { deckInfoJSON } = location.state || {};
    const [initialized, setInitialized] = useState(false);

    const deckInfo = deckInfoJSON ? JSON.parse(deckInfoJSON) : {};
    const { cardList, cartaDelMazo } = deckInfo;

    const getUrlImage = (codigoCarta, isTrasera) => {
        let urlTemp = UsoLocal('');

        if(/_dia1_|_dia2_|_dia3_/.test(codigoCarta)){
            urlTemp += 'primeros-dias%2F'+codigoCarta // Buscar en contenedor primeros días
        }
        if(/encuentro_general/.test(codigoCarta)){
            urlTemp += 'encuentros-general%2F'+codigoCarta // Buscar en encuentros generales
        }
        if(/_solo_/.test(codigoCarta)){
            urlTemp += 'encuentros-solitario%2F'+codigoCarta // Ajusta la URL de acuerdo con tu estructura
        }
        if(/encuentro_tirano/.test(codigoCarta)){
            urlTemp += 'encuentros-tirano%2F'+codigoCarta // Ajusta la URL de acuerdo con tu estructura
        }
        if(/encuentro_especial/.test(codigoCarta)){
            urlTemp += 'encuentros-especial%2F'+codigoCarta // Ajusta la URL de acuerdo con tu estructura
        }

        // Añadir la terminación según el estado de la imagen
        urlTemp += isTrasera ? '_trasera.jpg?alt=media' : '_frontal.jpg?alt=media';

        return urlTemp;
    };

    const getImageCode = (urlCarta) => {
        let urlTemp = UsoLocal('');

        if (/(_dia1_|_dia2_|_dia3_)/.test(urlCarta)) {
            urlTemp += urlCarta.replace('primeros-dias%2F', '');
        } else if (/encuentro_general/.test(urlCarta)) {
            urlTemp += urlCarta.replace('encuentros-general%2F', '');
        } else if (/_solo_/.test(urlCarta)) {
            urlTemp += urlCarta.replace('encuentros-solitario%2F', '');
        } else if (/encuentro_tirano/.test(urlCarta)) {
            urlTemp += urlCarta.replace('encuentros-tirano%2F', '');
        } else if (/encuentro_especial/.test(urlCarta)) {
            urlTemp += urlCarta.replace('encuentros-especial%2F', '');
        }

        return urlTemp.replace(/(_frontal\.jpg?alt=media|_trasera\.jpg?alt=media)\?alt=media$/, '');
    };

    useEffect(() => {
        // Inicializar URLs la primera vez que se monta el componente
        if (!initialized && cardList && cardList.length > cartaDelMazo) {
            const nextUrls = cardList.slice(cartaDelMazo, cartaDelMazo + 2);
            setUrls(nextUrls.map((codigoCarta, index) => getUrlImage(codigoCarta, imageStates[index])));
            setInitialized(true);
        }
    }, [cardList, cartaDelMazo, initialized, imageStates]);

    const handleImageClick = (index) => {
        // Alternar entre frontal y trasera para la imagen seleccionada
        setImageStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });

        // Actualizar la URL de la imagen que ha sido clicada
        setUrls(prevUrls => {
            const newUrls = [...prevUrls];
            newUrls[index] = getUrlImage(cardList[cartaDelMazo + urlsOrder[index]], !imageStates[index]);
            return newUrls;
        });
    };

    const handleSwap = () => {
        // Intercambiar las URLs y sus estados
        setUrls([urls[1], urls[0]]);
        setImageStates([imageStates[1], imageStates[0]]);
        setUrlsOrder([urlsOrder[1], urlsOrder[0]]); // Cambiar también el orden
    };

    const showImage = (url) => {
        setSelectedImage(url);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const volverAventura = () => {
        // Reordenar cardList basado en el nuevo orden de urlsOrder
        const updatedCardList = [...cardList];
        updatedCardList[cartaDelMazo] = getImageCode(urls[0]);
        if (urls[1]) {
            updatedCardList[cartaDelMazo + 1] = getImageCode(urls[1]);
        }

        navigate('/Aventura', { state: { deckInfoJSON: JSON.stringify({ ...deckInfo, cardList: updatedCardList }) } });
    };

    return (
        <div className="revisar-proximos-encuentros">
            {/* Sección del botón "Volver a la aventura" */}
            <div className="seccion-boton-atras">
                <button className='boton-volverAventura' onClick={volverAventura}>
                    ← Volver a la aventura
                </button>
            </div>

            <div className="imagenes-container">
                {urls.map((url, index) => (
                    <div key={index} className="imagen-item">
                        <p>{index + 1}º</p>
                        <img
                            src={url}
                            alt={`Card ${index + 1}`}
                            onClick={() => handleImageClick(index)}
                            className="miniatura-imagen"
                        />
                        <button
                            className="zoom-icon"
                            onClick={() => showImage(url)}
                        >
                            <img
                                src={(UsoLocal("resources%2Flupa.jpg?alt=media"))}
                                alt="Zoom icon"
                            />
                        </button>
                    </div>
                ))}
            </div>
            <div className="flechas-container">
                <button onClick={handleSwap} className="flecha">← Cambiar orden →</button>
            </div>
            {selectedImage && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img src={selectedImage} alt="Enlarged view" />
                        <span className="close" onClick={closeModal}>×</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RevisarProximosEncuentros;
