import './Novedades.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Paper, Typography } from '@mui/material';
import axios from 'axios';

const Estadisticas = () => {
    const navigate = useNavigate();
    const [dataGearloc, setData] = useState([]);
    const [dataVillain, setDataVillain] = useState([]);

    useEffect(() => {
        fetchVillainStats();
        fetchGearlocStats();
    }, []);

    const fetchGearlocStats = async () => {
        try {
            const response = await axios.get('https://tmbadventures.es/api/gearloc_stats');
            console.log(response.data);

            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchVillainStats = async () => {
        try {
            const response = await axios.get('https://tmbadventures.es/api/villain_stats');
            console.log(response.data);
            setDataVillain(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const goToMain = () => {
        navigate('/');
    };

    return (
        <div className="faq-container">
            <h2>Estadísticas</h2>

            <h2>Partidas por Gearloc</h2>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        layout="vertical"
                        width={600}
                        height={400}
                        data={dataGearloc}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="name" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="partidas" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>

            <h2>Partidas por Tirano</h2>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        layout="vertical"
                        width={600}
                        height={400}
                        data={dataVillain}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="name" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="partidas" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </div>
    );
};

export default Estadisticas;