import React from 'react';
import './ExpansionSelector.css';  // Asegúrate de crear y usar este archivo CSS para estilos

const ExpansionSelector = ({ expansions, onToggle, selectedExpansions }) => {
    return (
        <div className="expansion-selector">
            <h3>Selecciona expansiones</h3>

            <div className="expansion-list">
                {expansions.map((expansion) => {
                    const isSelected = selectedExpansions.includes(expansion.name);
                    return (
                        <div 
                            key={expansion.name} 
                            className={`expansion-item-checkbox ${isSelected ? 'selected' : ''}`}
                            onClick={() => onToggle(expansion.name, !isSelected)}
                        >
                            <img 
                                src={expansion.image} 
                                alt={expansion.name} 
                                className="expansion-image-checkbox"
                            />
                            <img 
                                src="/static/resources/check.png" 
                                alt="icon" 
                                className={`icon-overlay ${isSelected ? 'selected' : ''}`}
                            />
                            <span className="expansion-text">{expansion.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ExpansionSelector;
